import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-mail-confirmation',
  templateUrl: './mail-confirmation.component.html',
  styleUrl: './mail-confirmation.component.sass'
})
export class MailConfirmationComponent  implements OnInit {

  confirm: boolean = false;

  constructor(
    private route: ActivatedRoute
  )
  {}

  ngOnInit() {

    this.route.queryParams.subscribe(params => {

      const confirmKey = params['confirm_key'];

      if(confirmKey)
      {
        this.confirm = true;
      }
      else
        this.confirm = false;

    });

  }

}
