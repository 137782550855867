import { API_SERVER } from "../variables/API";

export const REGISTRATION_USER = `${API_SERVER}registration`;

export const GOOGLE_REGISTRATION_USER = `${API_SERVER}googleregistration`

export const FIND_USER = `${API_SERVER}finduser`;

export const ACCOUNT_RECOVERY_USER = `${API_SERVER}accountrecovery`;

export const GET_CODE_USER = `${API_SERVER}getcode`;

export const VERIFICATION_CODE_USER = `${API_SERVER}verificationcode`;

