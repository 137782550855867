import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { PLAN_FEATURENAME, planReducer } from './store/plan.reducer';
import { metaReducersWithLocalStorage } from '../local-storage/localstorage.reducer';
import { PlanEffects } from './store/plan.effects';
import { RequestInterceptor } from '../interceptors/request.interceptor';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(
      PLAN_FEATURENAME,
      planReducer,
      { metaReducers: metaReducersWithLocalStorage }
    ),
    EffectsModule.forFeature([PlanEffects])
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    }
  ]
})

export class PlanStoreModule { }
