import { API_SERVER } from '../variables/API';


export const GET_LANGUAGES = `${API_SERVER}getlanguages`

export const RECEIVING_FILE = `${API_SERVER}receivingfile`

export const UPLOAD_FILE = `${API_SERVER}uploadfile`

export const GET_HISTORY_FILE = `${API_SERVER}gethistoryfile`

export const DELETE_HISTORY = `${API_SERVER}deletehistory`

export const REWRITE_FILE = `${API_SERVER}rewritefile`

export const HUMANIZED_FILE = `${API_SERVER}humanizedfile`

export const DOWNLOAD_FILE = `${API_SERVER}downloadfile`

export const ADD_PROMO_FILE = `${API_SERVER}addpromofile`

export const SUPPORT_MESSAGE = `${API_SERVER}support/message`
