import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  debounceTime,
  distinctUntilChanged,
  Observable,
  Subject,
  takeUntil,
} from 'rxjs';
import { UniqueUserValidator } from '../../../core/validator/unique-user.validator';
import { MatDialog } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { passwordMatchValidator } from '../../../core/validator/password-match.validator';
import {
  FindUser,
  Registration,
  RegistrationGoogle,
  RegistrationReset,
} from '../../../core/store/reg-store/store/reg.actions';
import { ErrorRegDialogComponent } from '../../dialog/error-reg-dialog/error-reg-dialog.component';
import { ConfirmationDialogComponent } from '../../dialog/confirmation-dialog/confirmation-dialog.component';
import {
  getExistsEmailStatus,
  getExistsLoginStatus,
  getRegStatus,
} from '../../../core/store/reg-store/store/reg.selectors';
import {
  AddPromoFileSuccess,
  ReceivingFileSuccess,
  ResetPromoFile,
} from '../../../core/store/app-store/store/app.actions';
import { jwtDecode } from 'jwt-decode';
import { AuthGoogle } from '../../../core/store/auth-store/store/auth.actions';

declare const google: any;

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.sass'],
})
export class RegistrationComponent implements OnInit, AfterViewInit, OnDestroy {
  showRegisterEmail: boolean = false;
  formReg: FormGroup;
  getExistsLoginStatus$: Observable<boolean> = this.store$.pipe(
    select(getExistsLoginStatus)
  );
  getExistsEmailStatus$: Observable<boolean> = this.store$.pipe(
    select(getExistsEmailStatus)
  );
  getRegStatus$: Observable<any> = this.store$.pipe(select(getRegStatus));
  private destroy$ = new Subject<void>();
  queryPromoFile: any;
  hide = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store$: Store,
    private formBuilder: FormBuilder,
    private uniqueUserValidator: UniqueUserValidator,
    private dialog: MatDialog,
    private actions$: Actions
  ) {
    this.formReg = this.formBuilder.group({
      login: ['', [Validators.required]],
      last_name: ['', Validators.required],
      first_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      passwords: this.formBuilder.group(
        {
          password: ['', [Validators.required, Validators.minLength(6)]],
          confirmPassword: ['', Validators.required],
        },
        { validators: passwordMatchValidator }
      ),
    });

    this.actions$
      .pipe(ofType(AddPromoFileSuccess), takeUntil(this.destroy$))
      .subscribe(() => {
        this.store$.dispatch(ResetPromoFile());
      });

    this.getRegStatus$.pipe(takeUntil(this.destroy$)).subscribe((status) => {
      if (status === 'success') {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '300px',
          disableClose: true,
          closeOnNavigation: false,
        });

        dialogRef.afterClosed().subscribe(() => {
          this.onShowLogIn();
        });
      } else if (status === 'failed') {
        const dialogRegError = this.dialog.open(ErrorRegDialogComponent, {
          width: '300px',
          disableClose: true,
          closeOnNavigation: false,
        });

        dialogRegError.afterClosed().subscribe(() => {});
      }

      this.store$.dispatch(RegistrationReset());
    });
  }

  ngOnInit(): void {
    this.loadGoogleApi();
    this.setupLoginInputListener();
    this.setupEmailInputListener();
    this.subscribeToExistenceChecks();

    this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length > 0) {
        const encodedPromoFile = params['promoFile'];
        const decodedPromoFile = JSON.parse(atob(encodedPromoFile));
        const { languageId, originalName, newName, fileType } =
          decodedPromoFile;

        this.store$.dispatch(
          ReceivingFileSuccess({
            status: true,
            languageId,
            originalName,
            newName,
            fileType,
          })
        );
      }
    });
  }

  ngAfterViewInit() {
    if (typeof google !== 'undefined') {
      this.initializeGoogleSignIn();
    } else {
      window.addEventListener(
        'google-loaded',
        this.initializeGoogleSignIn.bind(this)
      );
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.onload = () => window.dispatchEvent(new Event('google-loaded'));
      document.body.appendChild(script);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadGoogleApi() {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = () => this.initializeGoogleSignIn();
    document.body.appendChild(script);
  }

  initializeGoogleSignIn() {
    google.accounts.id.initialize({
      client_id:
        '1078394747503-sjmedrtjgbv9cdogsmfb1cdpr802ft1t.apps.googleusercontent.com',
      callback: this.onSignUpWithGoogle.bind(this),
    });
    google.accounts.id.renderButton(
      document.getElementById('google-register-btn'),
      { theme: 'outline', size: 'large' }
    );
  }

  private setupLoginInputListener(): void {
    const loginControl = this.formReg.get('login');
    if (loginControl) {
      loginControl.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          takeUntil(this.destroy$)
        )
        .subscribe((value: string) => {
          if (loginControl.status === 'VALID') {
            loginControl.setErrors(null);
          }
          this.store$.dispatch(FindUser({ login: value }));
        });
    }
  }

  private setupEmailInputListener(): void {
    const emailControl = this.formReg.get('email');
    if (emailControl) {
      emailControl.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          takeUntil(this.destroy$)
        )
        .subscribe((value) => {
          if (emailControl.status === 'VALID') {
            emailControl.setErrors(null);
          }
          this.store$.dispatch(FindUser({ email: value }));
        });
    }
  }

  private subscribeToExistenceChecks(): void {
    this.getExistsLoginStatus$
      .pipe(takeUntil(this.destroy$))
      .subscribe((exists) => {
        if (exists) this.onLoginExists();
      });
    this.getExistsEmailStatus$
      .pipe(takeUntil(this.destroy$))
      .subscribe((exists) => {
        if (exists) this.onEmailExists();
      });
  }

  onLoginExists(): void {
    const loginControl = this.formReg.get('login');
    if (loginControl) {
      loginControl.setErrors({ loginExists: true });
    }
  }

  onEmailExists(): void {
    const emailControl = this.formReg.get('email');
    if (emailControl) {
      emailControl.setErrors({ emailExists: true });
    }
  }

  onShowLogIn(): void {
    this.router.navigate(['/login']);
  }

  onReg(): void {
    if (this.formReg.valid) {
      const formattedData = {
        last_name: this.formReg.value.last_name,
        first_name: this.formReg.value.first_name,
        login: this.formReg.value.login,
        email: this.formReg.value.email,
        password: this.formReg.value.passwords.password,
      };

      this.store$.dispatch(Registration(formattedData));
    }
  }

  onShowRegisterEmail(): void {
    this.showRegisterEmail = !this.showRegisterEmail;
  }

  onSignUpWithGoogle(response: any): void {
    const auth = this.decodeJWT(response.credential);
    if (auth) {
      this.store$.dispatch(RegistrationGoogle(auth));
    }
  }

  onSignUpWithApple(): void {}

  decodeJWT(token: string): any {
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error('Failed to decode JWT:', error);
      return null;
    }
  }
}
