import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hideEmail'
})
export class HideEmailPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    }

    const [localPart, domainPart] = value.split('@');
    const localLen = localPart.length;
    const domainLen = domainPart.length;

    const hiddenLocal = localPart[0] + '*'.repeat(localLen - 2) + localPart.slice(-1);
    const hiddenDomain = domainPart[0] + '*'.repeat(domainLen - 3) + domainPart.slice(-2);

    return `${hiddenLocal}@${hiddenDomain}`;
  }

}
