import { createAction, props } from "@ngrx/store";
import { Billing, PlanCheckRequest, SettingsUser, User } from "../interface/user.interface";
import { SubscriptionUser } from "../../subscription-store/interface/subscription.interface";

export const GetSettingUser = createAction(
  '[GetSettingUser]'
)

export const GetSettingUserSuccess = createAction(
  '[GetSettingUser] success',
  props<SettingsUser>()
)

export const GetSettingUserFailed = createAction(
  '[GetSettingUser] failed'
)

export const GetUserData = createAction(
  '[GetUserData]'
)

export const GetUserDataSuccess = createAction(
  '[GetUserData] success',
  props<User>()
)

export const GetUserDataFailed = createAction(
  '[GetUserData] failed'
)

export const PlanCheck = createAction(
  '[PlanCheck] get',
  props<PlanCheckRequest>()
)

export const PlanCheckSuccess = createAction(
  '[PlanCheck] success',
  props<SubscriptionUser>()
);

export const PlanCheckFailed = createAction(
  '[PlanCheck] failed'
)

export const GetBilling = createAction(
  '[GetBilling] get'
)

export const GetBillingSuccess = createAction(
  '[GetBilling] success',
  props<{ billing: Billing[] }>()
);

export const GetBillingFailed = createAction(
  '[GetBilling] failed'
)

export const ResetUser = createAction(
  '[ResetUser]'
)

export const UploadIco = createAction(
  '[UploadIco]',
  props<{ data: FormData }>()
)

export const UploadIcoSuccess = createAction(
  '[UploadIco] success',
  props<{ ico: string }>()
)

export const UploadIcoFailed = createAction(
  '[UploadIco] failed'
)

