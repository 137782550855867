import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, first, map, of, tap, timeout, switchMap } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { getExistsLoginStatus, getExistsEmailStatus } from '../store/reg-store/store/reg.selectors';

@Injectable({ providedIn: 'root' })
export class UniqueUserValidator {
  constructor(private store$: Store) {}

  loginValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return control.valueChanges.pipe(
        switchMap(() => this.store$.pipe(
          select(getExistsLoginStatus),
          map(exists => (exists ? { loginExists: true } : null)),
        ))
      );
    };
  }
  

  emailValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return control.valueChanges.pipe(
        switchMap(() => this.store$.pipe(
          select(getExistsEmailStatus),
          map(exists => (exists ? { emailExists: true } : null)),

        ))
      );
    };
  }

  findUser(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.store$.pipe(
        select(getExistsLoginStatus),
        tap(exists => console.log('Login exists: ', exists)),
        map(exists => exists === false ? { userExists: true } : null),
        first()
      );
    };
  }

  emailCodeValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const code: string = control.value;
      const regex = /^\d{3}-\d{3}-\d{3}$/;

      if (!code) {
        return of(null);
      }

      const valid = regex.test(code);

      return of(valid ? null : { invalidCode: true });
    };
  }

}
