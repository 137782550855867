import { createAction, props } from "@ngrx/store";
import { SubscriptionCancelRequest, SubscriptionRequest, SubscriptionUser } from "../interface/subscription.interface";

export const Subscription = createAction(
  '[Subscription]',
  props<{subscribe: SubscriptionRequest}>()
)

export const SubscriptionSuccess = createAction(
  '[Subscription] success'
)

export const SubscriptionFailed = createAction(
  '[Subscription] failed'
)

export const GetSubscription = createAction(
  '[GetSubscription]'
)

export const GetSubscriptionSuccess = createAction(
  '[GetSubscription] success',
  props<SubscriptionUser>()
)

export const GetSubscriptionFailed = createAction(
  '[GetSubscription] failed'
)

export const SubscriptionCancel = createAction(
  '[SubscriptionCancel]',
  props<SubscriptionCancelRequest>()
)

export const SubscriptionCancelSuccess = createAction(
  '[SubscriptionCancel] success'
)

export const SubscriptionCancelFailed = createAction(
  '[SubscriptionCancel] failed'
)

export const ResetSubscription = createAction(
  '[ResetSubscription]'
)
