<div class="billing-page">
  <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="purchaseDate">
      <mat-header-cell *matHeaderCellDef> Purchase Date </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.event_time | date}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="expirationDate">
      <mat-header-cell *matHeaderCellDef> Purchase Date </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.billing_period_end | date}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="productName">
      <mat-header-cell *matHeaderCellDef> Product Name </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.plan_name}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef> Payment Status </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.status}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
