<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div id="file-rewrite">
    <div class="file-upload-header">
      <h2>Upload a File</h2>
    </div>
    <div
      class="file-upload-area"
      [class.file-upload-area-dragover]="isDraggingOver"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
      (click)="openFileSelector()"
    >
      <div class="file-drag-drop">
        <ng-container *ngIf="uploadedFileName; else uploadPrompt">
          <div>
            File uploaded: {{ uploadedFileName }}
            <i class="fa fa-times file-remove" (click)="removeFile()"></i>
          </div>
        </ng-container>
        <ng-template #uploadPrompt>
          Drag and drop files here, or
          <input type="file" (change)="onFileSelected($event)" [accept]="acceptedFileTypes" hidden #fileInput>
          <b>browse</b> your computer.
          Allowed file size: up to 10 MB
          <h3><b>.DOCX .PDF .TXT</b></h3>
        </ng-template>
      </div>
    </div>

    <div class="progress-container" *ngIf="isUploading">
      <mat-progress-bar mode="determinate" [value]="uploadProgress"></mat-progress-bar>
      <div class="progress-ball" [style.left.%]="uploadProgress">
        <div class="pricent">
          {{ uploadProgress | number: '1.0-0' }}%
        </div>
      </div>
    </div>

    <div class="status">
      {{ currentStatus }}
    </div>

    <div class="language-selection">
      <mat-form-field appearance="fill">
        <mat-label>Select source language</mat-label>
        <mat-select formControlName="language">
          <mat-option *ngFor="let language of languages$ | async" [value]="language.id_languages">
            {{ language.full_name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="upload-action">
      <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid || isUploading">Get New Text</button>
    </div>
    <div class="file-upload-footer">
      In accordance with our privacy and security measures, all user-uploaded files are subject to automatic deletion from our servers within 24 hours of their upload.
      <br>
      Please note that the process of uploading a document implies your agreement with our Terms of Use and Privacy Policy.
    </div>
  </div>
</form>



