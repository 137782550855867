import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { GET_PLANS } from '../../../global/request/plan';
import { HttpClient } from '@angular/common/http';
import { PlanAnswer, PlanDescriptionState } from '../interface/plan.interface';

@Injectable({
  providedIn: 'root'
})
export class PlanService {



  constructor(
    private httpClient: HttpClient
  )
  {

  }



  getPlans(): Observable<PlanAnswer> {

    return this.httpClient.post<PlanAnswer>(GET_PLANS, {})
    .pipe(
      map( (res) => {

        if (Array.isArray(res.data)) {
          for(let plan of res.data)
          {
            plan.description = this.parseJsonOrDefault(plan.description);
          }
        }

        return res;
      })
    );

  }

  parseJsonOrDefault(jsonString: string | PlanDescriptionState[]): PlanDescriptionState[] {

    let _json: PlanDescriptionState[] = [];

    if (typeof jsonString === 'string') {
      try {
        _json = JSON.parse(jsonString);
      }
      catch (error)
      {
        return _json;
      }
    }

    return _json;
  }

}
