import { Component, ViewChild } from '@angular/core';

import { MatSidenav } from '@angular/material/sidenav';
import { MatMenuTrigger } from '@angular/material/menu';
import { Store, select } from '@ngrx/store';
import { filter, Observable } from 'rxjs';
import { getAuthStatus } from '../../core/store/auth-store/store/auth.selectors';
import { MatDialog } from '@angular/material/dialog';
import { ExponFileRewriteComponent } from '../presentation/expon-file-rewrite/expon-file-rewrite.component';
import { AppCookieService } from '../../core/services/cookie/app-cookie.service';
import { VERIFIED_USER } from '../../core/global/variables/app';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { userData, userSettingData } from '../../core/store/user-store/store/user.selectors';
import { SettingsUser, User } from '../../core/store/user-store/interface/user.interface';
import { ServiceWorksDialogComponent } from '../dialog/service-works-dialog/service-works-dialog.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ResetApp } from '../../core/store/app-store/store/app.actions';
import { ResetAuth } from '../../core/store/auth-store/store/auth.actions';
import { ResetPaddle } from '../../core/store/paddle-store/store/paddle.actions';
import { ResetPlan } from '../../core/store/plan-store/store/plan.actions';
import { ResetReg } from '../../core/store/reg-store/store/reg.actions';
import { ResetSubscription } from '../../core/store/subscription-store/store/subscription.actions';
import { ResetUser } from '../../core/store/user-store/store/user.actions';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.sass'
})

export class MenuComponent {

  @ViewChild('sidenav') sidenav?: MatSidenav;
  @ViewChild('menuTrigger') menuTrigger?: MatMenuTrigger;

  currentMenuTitle: string = 'Statistics';
  menuOpen: boolean = false;

  isHandset: boolean = false;

  getAuthStatus$: Observable<boolean> = this.store$.pipe(select(getAuthStatus))
  getAuthStatus: boolean = false;

  userSettingData$: Observable<SettingsUser> = this.store$.pipe(select(userSettingData))

  currentUrl: string = '';
  currentTabIndex: number = 0;

  userData$: Observable<User> = this.store$.pipe(select(userData))
  userData!: User;

  constructor(
    private store$: Store,
    public dialog: MatDialog,
    private appCookieService: AppCookieService,
    private router: Router,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver
  )
  {

    this.userData$.subscribe(user => this.userData = user);

    this.userSettingData$.subscribe( user => {
      if( user.petuh_ebani )
      {
        this.dialog.open(ServiceWorksDialogComponent, {
          width: '600px',
          disableClose: true,
          data: {}
        });
      }
    })

    this.getAuthStatus$.subscribe( getAuthStatus => {

      const verifiedUserBrowser = (
        (!!this.appCookieService.getCookie(VERIFIED_USER) && this.appCookieService.getCookie(VERIFIED_USER) === '1')
        ||
        (!!localStorage.getItem(VERIFIED_USER) && localStorage.getItem(VERIFIED_USER) === '1')
      );

      if(!getAuthStatus && !verifiedUserBrowser)
      {
        // this.openUploadDialog()
        this.router.navigate(['/register']);
      }
      else if( !getAuthStatus )
      {
        this.router.navigate(['/login']);
      }

    })

    this.router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd)
    ).subscribe((event: Event) => {
      const navEndEvent = event as NavigationEnd;

      this.currentUrl = this.pathFormatting(navEndEvent.url);

      if (this.currentUrl.includes('Statistics')) {
        this.currentTabIndex = 0;
      } else if (this.currentUrl.includes('File Rewrite')) {
        this.currentTabIndex = 1;
      } else if (this.currentUrl.includes('Subscription')) {
        this.currentTabIndex = 2;

      } else if (this.currentUrl.includes('Support')) {
        this.currentTabIndex = 3;
      }

    });

  }

  ngOnInit() {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet
    ]).subscribe(result => {
      this.isHandset = result.matches;
      // console.log("Is Handset: ", this.isHandset);
    });

    this.route.url.subscribe(url => {
      this.currentMenuTitle = url.join('/');
    });
  }

  pathFormatting(url: string): string {

    let lastNameURL = url.split('/').pop() || '';

    return lastNameURL.replace(/[_-]/g, ' ')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

  }

  openUploadDialog(): void {
    const dialogRef = this.dialog.open(ExponFileRewriteComponent, {
      width: '800px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate(['/login']);
    });
  }

  updateMenuTitle(title: string) {
    this.currentMenuTitle = title;
  }

  onMenuItemSelected(): void {
    if (this.sidenav) {
      this.sidenav.close();
    }

    if (this.menuTrigger) {
      this.menuTrigger.closeMenu();
    }
  }

  closeSidenav(menuTitle: string): void {
    this.updateMenuTitle(menuTitle);
    if (this.sidenav) {
      this.sidenav.close();
    }
  }

  onLogOut(): void {

    this.logout();

  }

  logout(): void {
    this.store$.dispatch(ResetApp());
    this.store$.dispatch(ResetAuth());
    this.store$.dispatch(ResetPaddle());
    this.store$.dispatch(ResetPlan());
    this.store$.dispatch(ResetReg());
    this.store$.dispatch(ResetSubscription());
    this.store$.dispatch(ResetUser());
  }

  toggleMenu(): void {
    this.menuOpen = !this.menuOpen;
  }

  onTabChange(event: MatTabChangeEvent) {
    switch (event.index) {
      case 0:
        this.router.navigate(['/aiworker/statistics']);
        break;
      case 1:
        this.router.navigate(['/aiworker/file-rewrite']);
        break;
      case 2:
        this.router.navigate(['/aiworker/subscription']);
        break;
      case 3:
        this.router.navigate(['/aiworker/support']);
        break;
      default:
        break;
    }

    this.currentTabIndex = event.index;
  }

}
