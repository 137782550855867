import { createReducer, on } from '@ngrx/store';
import { SubscriptionState } from '../interface/subscription.interface';
import { GetSubscriptionFailed, GetSubscriptionSuccess, ResetSubscription } from './subscription.actions';
import { PlanCheckSuccess } from '../../user-store/store/user.actions';

export const SUBSCRIPTION_FEATURENAME = "subscription";

const subscriptionState: Partial<SubscriptionState> = {
  active: false,
  user: {
    user_plan_id: 0,
    user_id: 0,
    plan_id: 0,
    start_date: null,
    end_date: null,
    payment_id: 0,

    daily_files_limit: 5,
    file_sizes: 10,

    monthly_rewriting_file: 0,
    max_monthly_rewriting_file: 0,

    monthly_humanized_file: 0,
    max_monthly_humanized_file: 0,

    package_rewriting_file: 0,
    package_humanized_file: 0,

    subscription_type: "",
    plan_name: "",

    trialing: 0,

    package_rewriting: 0,
    package_keyword: 0,
    package_generation: 0,
    monthly_rewriting: 0,
    monthly_keyword: 0,
    monthly_generation: 0,
    max_monthly_rewriting: 0,
    max_monthly_keyword: 0,
    max_monthly_generation: 0
  }
};

export const subscriptionReducer = createReducer(
  subscriptionState,

  on(GetSubscriptionSuccess, PlanCheckSuccess, (state, user) => ({
    ...state,
    active: true,
    user
  })),

  on(GetSubscriptionFailed, (state) => ({
    ...state,
    active: false
  })),

  on(ResetSubscription, (state) => ({
    ...state,
    ...subscriptionState
  }))

);
