import { createAction, props } from '@ngrx/store';
import { AccountRecoveryRequest, FindUserRequest, GetCodeRequest, RegRequest, VerificationCodeRequest } from '../interface/reg.interface';
import { GoogleData } from '../../auth-store/interface/auth.interface';

export const RegistrationGoogle = createAction(
  '[RegistrationGoogle] user',
  props<GoogleData>()
)

export const RegistrationGoogleSuccess = createAction(
  '[RegistrationGoogle] user success'
)

export const RegistrationGoogleFailed = createAction(
  '[RegistrationGoogle] user failed'
)

export const RegistrationGoogleWarning = createAction(
  '[RegistrationGoogle] user warning'
)

export const Registration = createAction(
  '[Registration] user',
  props<RegRequest>()
)

export const RegistrationSuccess = createAction(
  '[Registration] user success'
)

export const RegistrationFailed = createAction(
  '[Registration] user failed'
)

export const RegistrationWarning = createAction(
  '[Registration] user warning'
)


export const RegistrationReset = createAction(
  '[Registration] user reset'
)

export const SaveStatusClient = createAction(
  '[SaveStatusClient] save status',
  props<{statusCient: boolean}>()
)

export const FindUser = createAction(
  '[FindUser]',
  props<FindUserRequest>()
)

export const FindUserSuccess = createAction(
  '[FindUser] success',
  props<{existsType: string}>()
)

export const FindUserFailed = createAction(
  '[FindUser] failed'
)

export const AccountRecovery = createAction(
  '[AccountRecovery]',
  props<AccountRecoveryRequest>()
)

export const AccountRecoverySuccess = createAction(
  '[AccountRecovery] success'
)

export const AccountRecoveryFailed = createAction(
  '[AccountRecovery] failed'
)

export const GetCode = createAction(
  '[GetCode]',
  props<GetCodeRequest>()
)

export const GetCodeSuccess = createAction(
  '[GetCode] success',
  props<{email: string}>()
)

export const GetCodeFailed = createAction(
  '[GetCode] failed'
)

export const VerificationCode = createAction(
  '[VerificationCode]',
  props<VerificationCodeRequest>()
)

export const VerificationCodeSuccess = createAction(
  '[VerificationCode] success'
)

export const VerificationCodeFailed = createAction(
  '[VerificationCode] failed'
)

export const ResetReg = createAction(
  '[ResetReg]'
)
