import { API_SERVER } from '../variables/API';


export const GET_USER_DATA = `${API_SERVER}getuserdata`

export const GET_SETTING_USER = `${API_SERVER}getsettinguser`

export const PLAN_CHECK = `${API_SERVER}plancheck`

export const GET_BILLING = `${API_SERVER}getbilling`

export const UPLOUD_ICO = `${API_SERVER}uploadico`
