import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, catchError, concat, interval, map, mergeMap, of, switchMap, take, tap, throwError, timer } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ADD_PROMO_FILE, DELETE_HISTORY, DOWNLOAD_FILE, GET_HISTORY_FILE, GET_LANGUAGES, HUMANIZED_FILE, RECEIVING_FILE, REWRITE_FILE, SUPPORT_MESSAGE, UPLOAD_FILE } from '../../../global/request/app';
import { PromoFile, ReceivingFileAnswer } from '../interface/receivingfile.interface';
import { GetLanguagesAnswer } from '../interface/languages.interface';
import { UploadFileAnswer } from '../interface/uloadfile.interface';
import { DeleteHistoryAnswer, DeleteHistoryRequest, HistoryFileAnswer, HistoryFileRequest } from '../interface/historyfile.interface';
import { RewriteFileAnswer, RewriteFileRequest } from '../interface/rewritefile.interface';
import { DownloadFileAnswer, DownloadFileRequest } from '../interface/downloadfile.interface';
import { AddPromoFileAnswer, AddPromoFileRequest } from '../interface/addpromofile.interface';
import { HumanizedFileAnswer, HumanizedFileRequest } from '../interface/humanizedfile.interface';
import { SupportMessageAnswer, SupportMessageRequest } from '../interface/support.interface';
import { GetHistoryFile } from '../store/app.actions';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { MessageSendingConfirmationDialogComponent } from '../../../../components/dialog/message-sending-confirmation-dialog/message-sending-confirmation-dialog.component';
import { ExceedingLimitDialogComponent } from '../../../../components/dialog/exceeding-limit-dialog/exceeding-limit-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AppService implements OnDestroy {

  private restartInterval$ = new BehaviorSubject<void>(undefined);
  private subscription: Subscription = new Subscription();

  constructor(
    private httpClient: HttpClient,
    private store$: Store,
    private dialog: MatDialog
  )
  {}

  supportConfirmMessage(): void {

    this.dialog.open(MessageSendingConfirmationDialogComponent, {
      width: '600px',
      disableClose: true,
      data: {}
    });

  }

  getLanguages(): Observable<GetLanguagesAnswer> {

    return this.httpClient.post<GetLanguagesAnswer>(GET_LANGUAGES, {})
    .pipe(
      mergeMap(res => {
        if ('error' in res || 'warning' in res) {
          return throwError(res);
        }
        return [res];
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  receivingFile(formData: FormData): Observable<PromoFile> {
    return this.httpClient.post<ReceivingFileAnswer>(RECEIVING_FILE, formData)
    .pipe(
      mergeMap(res => {
        if ('error' in res || 'warning' in res) {
          return throwError(res);
        }
        return [res.data];
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  uploadFile(formData: FormData): Observable<UploadFileAnswer> {
    return this.httpClient.post<UploadFileAnswer>(UPLOAD_FILE, formData)
    .pipe(
      mergeMap(res => {
        if ('error' in res || 'warning' in res) {
          return throwError(res);
        }
        return [res];
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  getHistoryFile(data: HistoryFileRequest): Observable<HistoryFileAnswer> {
    const {type, ...req}: any = data;
    return this.httpClient.post<HistoryFileAnswer>(GET_HISTORY_FILE, req)
    .pipe(
      mergeMap(res => {
        if ('error' in res || 'warning' in res) {
          return throwError(res);
        }
        return [res];
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  deleteHistory(data: DeleteHistoryRequest): Observable<DeleteHistoryAnswer> {
    const {type, ...req}: any = data;
    return this.httpClient.post<HistoryFileAnswer>(DELETE_HISTORY, req)
    .pipe(
      mergeMap(res => {
        if ('error' in res || 'warning' in res) {
          return throwError(res);
        }
        return [res];
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  rewriteFile(data: RewriteFileRequest): Observable<RewriteFileAnswer> {
    const {type, ...req}: any = data;
    return this.httpClient.post<HistoryFileAnswer>(REWRITE_FILE, req)
    .pipe(
      mergeMap(res => {
        if(res.code === 21025) {
          this.dialog.open(ExceedingLimitDialogComponent, {
            width: '600px',
            data: {}
          });
        }
        if ('error' in res) {
          return throwError(res);
        }
        return [res];
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  humanizedFile(data: HumanizedFileRequest): Observable<HumanizedFileAnswer> {
    const {type, ...req}: any = data;
    return this.httpClient.post<HumanizedFileAnswer>(HUMANIZED_FILE, req)
    .pipe(
      mergeMap(res => {
        if ('error' in res || 'warning' in res) {
          return throwError(res);
        }
        return [res];
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  downloadFile(data: DownloadFileRequest): Observable<Blob> {
    const {type, filename, ...req}: any = data;

    return this.httpClient.post<Blob>(DOWNLOAD_FILE, req, {
      responseType: 'blob' as 'json'
    }).pipe(
      tap(
        (response: Blob) => {
          if (response.size > 0)
          {
            const url = window.URL.createObjectURL(response);
            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          }
          else
          {
            console.error('Received empty file');
          }
        },
        error => {
          console.error('Download error:', error);
        }
      )
    );
  }

  addPromoFile(data: AddPromoFileRequest): Observable<AddPromoFileAnswer> {

    const {type, ...req}: any = data;
    return this.httpClient.post<AddPromoFileAnswer>(ADD_PROMO_FILE, req)
    .pipe(
      mergeMap(res => {
        if ('error' in res || 'warning' in res) {
          return throwError(res);
        }
        return [res];
      }),
      catchError(error => {
        return throwError(error);
      })
    );

  }

  sendSupportMessage(data: SupportMessageRequest): Observable<SupportMessageAnswer> {

    const {type, ...req}: any = data;
    return this.httpClient.post<SupportMessageAnswer>(SUPPORT_MESSAGE, req)
    .pipe(
      mergeMap(res => {
        if ('error' in res || 'warning' in res) {
          return throwError(res);
        }
        return [res];
      }),
      catchError(error => {
        return throwError(error);
      })
    );

  }

  startDispatchingGetHistoryFile(formattedDate: string, itemsPerPage: number, pageIndex: number, searchName: string): void {

    this.subscription.unsubscribe();
    this.subscription = new Subscription();

    const initialCalls$ = interval(1000).pipe(
      take(3),
      tap(() => this.dispatchGetHistoryFile(formattedDate, itemsPerPage, pageIndex, searchName))
    );

    const subsequentCalls$ = timer(3000, 5000).pipe(
      take(24),
      tap(() => this.dispatchGetHistoryFile(formattedDate, itemsPerPage, pageIndex, searchName))
    );

    const combinedCalls$ = concat(initialCalls$, subsequentCalls$);

    this.restartInterval$.next();

    this.subscription.add(
      this.restartInterval$.pipe(
        switchMap(() => combinedCalls$)
      ).subscribe()
    );
  }

  private dispatchGetHistoryFile(formattedDate: string, itemsPerPage: number, pageIndex: number, searchName: string): void {
    let req = { limit: itemsPerPage, page: pageIndex + 1, ...(formattedDate && {date: formattedDate}), ...(searchName && {name: searchName}) };
    this.store$.dispatch(GetHistoryFile(req));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
