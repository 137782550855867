

<div id="box-file" class="upload-section">

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div id="file-rewrite">

      <div *ngIf="!isUploading" class="step">
        <div class="content">
          <div class="header-file">
            <h3>Choose a File</h3>
          </div>

          <div
            class="file-upload-area"
            [class.file-upload-area-dragover]="isDraggingOver"
            (dragover)="onDragOver($event)"
            (dragleave)="onDragLeave($event)"
            (drop)="onDrop($event)"
            (click)="openFileSelector()"
          >

            <div class="file-drag-drop">
              <ng-container *ngIf="uploadedFileName; else uploadPrompt">
                <div>
                  File uploaded: {{ uploadedFileName }}
                  <i class="fa fa-times file-remove" (click)="removeFile()"></i>
                </div>
              </ng-container>
              <ng-template #uploadPrompt>
                <input type="file" (change)="onFileSelected($event)" [accept]="acceptedFileTypes" hidden #fileInput>
                <p>Drag files here or <b>browse</b> your computer. Allowed file size: up to {{fileSizesPlan}} MB.</p>
                <h3><b>.DOCX .PDF .TXT</b></h3>
              </ng-template>
            </div>

          </div>

        </div>

      </div>

      <!-- isUploading -->
      <div *ngIf="isUploading" class="step">
        <div class="content ">

          <div class="header-file">
            <h3>Choose a File</h3>
          </div>

          <div class="file-upload-area ">
            <div class="file-drag-drop">

            </div>
          </div>

          <div class="file-loader">
            <div class="loader"></div>
            <div>
              File uploaded: {{ uploadedFileName }}
            </div>
          </div>

        </div>
      </div>


      <div class="step" *ngIf="!form.get('language')?.disabled">
        <div class="content">
          <div class="header-file">
            <h3>What your file language?</h3>
          </div>
          <div class="language-selection">
            <mat-form-field appearance="fill">
              <mat-label>Select source language</mat-label>
              <mat-select formControlName="language">
                <mat-option *ngFor="let language of languages$ | async" [value]="language.id_languages">
                  {{ language.full_name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="upload-action">
        <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid || isUploading">
          Upload a File
        </button>
      </div>

    </div>

  </form>

  <div class="filter-list ">

    <div class="box-filter" id="custom-outline">
      <mat-form-field appearance="outline">
        <mat-label>Search by file name</mat-label>
        <input matInput placeholder="Enter file name" (input)="applyFilter($event)">
      </mat-form-field>
    </div>

    <div class="box-filter" id="custom-outline">
      <mat-form-field appearance="outline">
        <mat-label>Search by date</mat-label>
        <input matInput [matDatepicker]="picker" placeholder="Choose a date" (dateChange)="applyDateFilter($event.value)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <!-- <div class="box-filter">
      <mat-form-field appearance="outline">
        <mat-label>Items per page</mat-label>
        <mat-select (selectionChange)="onItemsPerPageChange($event.value)">
          <mat-option *ngFor="let item of itemsPerPageSelect" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->

  </div>

  <div class="file-list-container">

    <div class="file-list">

      <app-file-panel
        *ngFor="let history of historys"
        [history]="history"
        [formattedDate]="formattedDate"
        [searchName]="searchName"
        [itemsPerPage]="itemsPerPage"
        [pageIndex]="pageIndex"
      ></app-file-panel>

    </div>

    <mat-paginator
      [length]="totalItems"
      [pageIndex]="pageIndex"

      [pageSize]="itemsPerPage"
      [pageSizeOptions]="itemsPerPageSelect"

      showFirstLastButtons
      (page)="onPageChange($event)">
    </mat-paginator>

  </div>

</div>




