import { createAction, props } from "@ngrx/store";
import { GetPaddleDataAnswer, GetPaddleDataRequest } from "../interface/paddle.interface";


export const PaddleCheckout = createAction(
  '[Paddle]',
  props<GetPaddleDataRequest>()
)

export const PaddleCheckoutSuccess = createAction(
  '[Paddle] success',
  props<GetPaddleDataAnswer>()
)

export const PaddleCheckoutFailed = createAction(
  '[Paddle] failed'
)

export const ResetPaddle = createAction(
  '[ResetPaddle]'
)
