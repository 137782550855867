import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SettingDialogComponent } from '../dialog/setting-dialog/setting-dialog.component';
import {UploadFile} from "../../core/store/app-store/store/app.actions";
import {Store} from "@ngrx/store";
import {UploadIco} from "../../core/store/user-store/store/user.actions";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.sass']
})
export class SettingsComponent {

  formIco: FormGroup;
  isUploading: boolean = false;
  profilePicture = "";

  constructor(
    public dialog: MatDialog,
    private store$: Store,
    private fb: FormBuilder
  )
  {
    this.formIco = this.fb.group({
      file: [null, Validators.required]
    });

  }

  openDialog(message: string): void {
    this.dialog.open(SettingDialogComponent, {
      width: '250px',
      data: { message }
    });
  }

  onProfilePictureChange(event: any) {
    const file = event.target.files[0];

    if (file) {

      if (!file.type.match('image/jpeg') && !file.type.match('image/png')) {
        this.openDialog('Only JPEG or PNG image files are accepted.');
        return;
      }

      if (file.size > 10 * 1024 * 1024) {
        this.openDialog('The file size must not exceed 10 MB.');
        return;
      }

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const img = new Image();
        img.onload = () => {

          if (img.width > 512 || img.height > 512) {
            this.openDialog('The image size must not exceed 512x512 pixels.');
          } else {
            this.profilePicture = e.target.result;
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }


  onSubmitIco() {
    if (this.formIco.valid) {
      this.isUploading = true;
      const formData: FormData = new FormData();
      const fileToUpload = this.formIco.get('file')?.value;
      formData.append('file', fileToUpload, fileToUpload.name);

      this.store$.dispatch(UploadIco({data: formData}));
    }
  }

}
