import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AppCookieService {

  constructor(
    private cookieService: CookieService
  ) { }

  setCookieForever(name: string, value: string) {
    const d = new Date();
    d.setFullYear(d.getFullYear() + 10);
    this.cookieService.set(name, value, d);
  }

  getCookie(name: string): string {
    return this.cookieService.get(name);
  }

}
