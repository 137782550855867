import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { SupportMessage } from '../../core/store/app-store/store/app.actions';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingPaymentDialogComponent } from '../dialog/loading-payment-dialog/loading-payment-dialog.component';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrl: './support.component.sass'
})
export class SupportComponent {

  supportForm = this.fb.group({
    name: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    message: ['', [Validators.required, Validators.maxLength(2500)]]
  });

  dialogRef!: MatDialogRef<LoadingPaymentDialogComponent>;

  constructor(
    private fb: FormBuilder,
    private store$: Store,
    private dialog: MatDialog
  ) { }

  onSendSupportMessage(): void {

    if (this.supportForm.valid)
    {
      const {name, email, message} = this.supportForm.value;
      this.store$.dispatch(SupportMessage({name: name ?? "", email: email ?? "", message: message ?? ""}))
      this.supportForm.reset()
    }

  }

}
