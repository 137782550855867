<div class="confirmation">

  <div class="confirmation-container" *ngIf="confirm; else confirmationError">
    <h1>Account Confirmation</h1>
    <p>Your account has been successfully confirmed on our unique <b>NewTXT</b> service!</p>
    <a routerLink="/auth">Sign In</a>
  </div>

  <ng-template #confirmationError>
    <div class="error-container">
      <h1>Confirmation Error</h1>
      <p>There was an issue with your account confirmation. Please try the confirmation process again or contact support for assistance.</p>
      <a routerLink="/auth">Contact Support</a>
    </div>
  </ng-template>

</div>
