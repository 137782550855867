import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser'; //provideClientHydration

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthorizationComponent } from './components/login/authorization/authorization.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { BillingComponent } from './components/billing/billing.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';

import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MenuComponent } from './components/menu/menu.component';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { SettingsComponent } from './components/settings/settings.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';
import { AuthStoreModule } from './core/store/auth-store/auth-store.module';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment.development';
import { FileRewriteComponent } from './components/tools/file-rewrite/file-rewrite.component';
import { PlanStoreModule } from './core/store/plan-store/plan-store.module';
import { AppStoreModule } from './core/store/app-store/app-store.module';
import { MatIconModule } from '@angular/material/icon';

import { PaddleStoreModule } from './core/store/paddle-store/paddle-store.module';
import { SubscriptionModule } from './core/store/subscription-store/subscription.module';
import { UserStoreModule } from './core/store/user-store/user-store.module';
import { RegStoreModule } from './core/store/reg-store/reg-store.module';

import { MatSelectModule } from '@angular/material/select';
import { ExponFileRewriteComponent } from './components/presentation/expon-file-rewrite/expon-file-rewrite.component';
import { CustomDialogComponent } from './components/dialog/custom-dialog/custom-dialog.component';
import { CookieService } from 'ngx-cookie-service';
import { MailConfirmationComponent } from './components/mail-confirmation/mail-confirmation.component';
import { ConfirmationDialogComponent } from './components/dialog/confirmation-dialog/confirmation-dialog.component';
import { ErrorRegDialogComponent } from './components/dialog/error-reg-dialog/error-reg-dialog.component';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FilePanelComponent } from './components/layout/file-panel/file-panel.component';
import { SupportComponent } from './components/support/support.component';
import { SettingDialogComponent } from './components/dialog/setting-dialog/setting-dialog.component';
import { LoadingPaymentDialogComponent } from './components/dialog/loading-payment-dialog/loading-payment-dialog.component';
import { ErrorLoadingPaymentDialogComponent } from './components/dialog/error-loading-payment-dialog/error-loading-payment-dialog.component';
import { ServiceWorksDialogComponent } from './components/dialog/service-works-dialog/service-works-dialog.component';
import { ExceedingLimitDialogComponent } from './components/dialog/exceeding-limit-dialog/exceeding-limit-dialog.component';
import { TrialExceedingLimitDialogComponent } from './components/dialog/trial-exceeding-limit-dialog/trial-exceeding-limit-dialog.component';
import { CancelSubscriptionDialogComponent } from './components/dialog/cancel-subscription-dialog/cancel-subscription-dialog.component';
import { MessageSendingConfirmationDialogComponent } from './components/dialog/message-sending-confirmation-dialog/message-sending-confirmation-dialog.component';
import { RegistrationComponent } from './components/login/registration/registration.component';

import { JwtModule } from '@auth0/angular-jwt';
import { FooterCompanyComponent } from './components/layout/footer-company/footer-company.component';
import { InfoPolicyComponent } from './components/layout/info-policy/info-policy.component';

import { MatTabsModule } from '@angular/material/tabs';

import { MatTooltipModule } from '@angular/material/tooltip';
import { AccountRecoveryComponent } from './components/login/account-recovery/account-recovery.component';
import { HideEmailPipe } from '../pipe/hide-email.pipe';
import { ErrorAuthDialogComponent } from './components/dialog/error-auth-dialog/error-auth-dialog.component';
import { ErrorAuthGoogleDialogComponent } from './components/dialog/error-auth-google-dialog/error-auth-google-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthorizationComponent,
    StatisticsComponent,
    BillingComponent,
    SubscriptionComponent,
    MenuComponent,
    SettingsComponent,
    FileRewriteComponent,
    ExponFileRewriteComponent,
    CustomDialogComponent,
    MailConfirmationComponent,
    ConfirmationDialogComponent,
    ErrorRegDialogComponent,
    FilePanelComponent,
    SupportComponent,
    SettingDialogComponent,
    LoadingPaymentDialogComponent,
    ErrorLoadingPaymentDialogComponent,
    ServiceWorksDialogComponent,
    ExceedingLimitDialogComponent,
    TrialExceedingLimitDialogComponent,
    CancelSubscriptionDialogComponent,
    MessageSendingConfirmationDialogComponent,
    RegistrationComponent,
    FooterCompanyComponent,
    InfoPolicyComponent,
    AccountRecoveryComponent,
    HideEmailPipe,
    ErrorAuthDialogComponent,
    ErrorAuthGoogleDialogComponent
  ],
  imports: [
    MatTooltipModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatMenuModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatPaginatorModule,
    MatTableModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    AuthStoreModule,
    PlanStoreModule,
    HttpClientModule,
    SubscriptionModule,
    MatSelectModule,
    UserStoreModule,
    AppStoreModule,
    RegStoreModule,
    MatProgressBarModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatPaginatorModule,
    FlexLayoutModule,
    PaddleStoreModule,
    MatIconModule,
    StoreModule.forRoot({}, {}),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    JwtModule,
    MatTabsModule
  ],
  providers: [
    MatNativeDateModule,
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
