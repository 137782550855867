<div class="switcher">
  <div
    class="switcher-month"
    [ngClass]="{
      'chose-month': !isTotalYearly
    }"
  >
    <span>In Monthly </span>
  </div>

  <div class="switcher-toggle">
    <mat-slide-toggle color="primary" (change)="togglePlan()"></mat-slide-toggle>
  </div>

  <div
    class="switcher-year"
    [ngClass]="{
      'chose-year': isTotalYearly
    }"
  >
    <span>In Yearly</span>
  </div>
</div>

<div id="subscription">
  <ng-container *ngFor="let plan of plans; let i = index; let last = last">
    <mat-card
      [ngClass]="{
        'plan-card': true,
        'plan-basic': i == 0,
        'plan-popular': i == 1,
        'plan-maximum': i == 2
      }"
      [class.last-card]="last"
    >
      <h2 mat-card-title class="title-plan">{{ (plan.active_trial === 1 && userData.use_trial === 0 && !getIsYearly) ? "Trial " : "" }}{{ plan.plan_name }}</h2>

      <mat-card-content>
        <ul>
          <li *ngFor="let row of plan.description">
            <i class="fa-solid {{row.ico}}"></i><p>{{row.text}}</p>
          </li>
        </ul>
      </mat-card-content>
      <mat-card-actions>
        <div class="price">
          <p class="plan-price">

            <span *ngIf="!getIsYearly">

              <ng-container *ngIf="plan.monthly_discount > 0 && (userData.use_trial === 0); else noDiscount">
                <strong class="plan-price-number">${{ plan.monthly_price * (1 - (plan.monthly_discount / 100)) | number:'1.0-2' }}</strong>
                <sup class="discount-tag"> - {{plan.monthly_discount}}%</sup>
                montly
              </ng-container>
              <!-- If not have discont on subscribe -->
              <ng-template #noDiscount>
                <strong class="plan-price-number">${{ plan.monthly_price }}</strong> montly
              </ng-template>

            </span>

            <span *ngIf="getIsYearly">

              <!-- If have discont on subscribe -->
              <ng-container *ngIf="plan.yearly_discount > 0; else noDiscount">
                <strong class="plan-price-number">${{ plan.yearly_price * (1 - (plan.yearly_discount / 100)) | number:'1.0-2' }}</strong>
                <sup class="discount-tag"> - {{plan.yearly_discount}}%</sup>
                year
              </ng-container>
              <!-- If not have discont on subscribe -->
              <ng-template #noDiscount>
                <strong class="plan-price-number">${{ plan.yearly_price }}</strong> year
              </ng-template>

            </span>

          </p>


          <ng-container *ngIf="!!userPlanData && plan.plan_id !== userPlanData.plan_id; else activePlan">

            <button
              type="button"
              mat-stroked-button
              class="subscribe-button primary"
              (click)="onSubscription(plan.plan_id)"
            >
              <i class="fas fa-check-circle"></i> {{userPlanData.plan_id > 1 ? "Upgrade" : "Subscribe"}}
            </button>

          </ng-container>

          <ng-template #activePlan>

            <button
              type="button"
              mat-stroked-button
              class="subscribe-button warn"
              (click)="onCancelSubscription()"
            >
              <i class="fas fa-times-circle"></i> Cancel
            </button>

          </ng-template>

        </div>
      </mat-card-actions>
    </mat-card>
  </ng-container>
</div>

