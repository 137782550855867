// auth.actions.ts
import { createAction, props } from '@ngrx/store';
import { AuthRequest, AuthState, GoogleData } from '../interface/auth.interface';


export const Authorization = createAction(
  '[Authorization] user',
  props<AuthRequest>()
)

export const AuthorizationSuccess = createAction(
  '[Authorization] user success',
  props<AuthState>()
)

export const AuthorizationFailed = createAction(
  '[Authorization] user failed'
)

export const AutoAuthorization = createAction(
  '[AutoAuthorization]',
  props<AuthState>()
)

export const AutoAuthorizationFailed = createAction(
  '[AutoAuthorization] user failed'
)

export const InitLogin = createAction(
  '[InitLogin]'
)

export const ResetAuth = createAction(
  '[ResetAuth]'
)

export const StartTokenVerification = createAction(
  '[StartTokenVerification]'
)

export const AuthGoogle = createAction(
  '[AuthGoogle]',
  props<GoogleData>()
)

export const AuthGoogleSuccess = createAction(
  '[AuthGoogle] google success',
  props<AuthState>()
)

export const AuthGoogleFailed = createAction(
  '[AuthGoogle] google failed'
)
