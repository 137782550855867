import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { getIsYearly, getPlans } from '../../core/store/plan-store/store/plan.selectors';
import { Observable, combineLatest, map } from 'rxjs';
import { PlansState } from '../../core/store/plan-store/interface/plan.interface';
import { IsYearly } from '../../core/store/plan-store/store/plan.actions';
import { Router } from '@angular/router';
import { SubscriptionRequest, SubscriptionUser } from '../../core/store/subscription-store/interface/subscription.interface';
import { PaddleCheckout } from '../../core/store/paddle-store/store/paddle.actions';
import { getSubscriptionUser } from '../../core/store/subscription-store/store/subscription.selectors';
import { SubscriptionCancel } from '../../core/store/subscription-store/store/subscription.actions';
import { userData } from '../../core/store/user-store/store/user.selectors';
import { User } from '../../core/store/user-store/interface/user.interface';
import { MatDialog } from '@angular/material/dialog';
import { CancelSubscriptionDialogComponent } from '../dialog/cancel-subscription-dialog/cancel-subscription-dialog.component';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrl: './subscription.component.sass'
})
export class SubscriptionComponent {

  isTotalYearly = false;

  plans$: Observable<PlansState[]> = this.store$.pipe(select(getPlans))
  plans: PlansState[] = [];

  getIsYearly$: Observable<boolean> = this.store$.pipe(select(getIsYearly))
  getIsYearly: boolean = false

  userPlanData$: Observable<SubscriptionUser> = this.store$.pipe(select(getSubscriptionUser))
  userPlanData!: SubscriptionUser;

  userData$: Observable<User> = this.store$.pipe(select(userData))
  userData!: User;

  constructor(
    private router: Router,
    private store$: Store,
    private dialog: MatDialog
  )
  {

    combineLatest([this.userPlanData$, this.plans$, this.userData$]).subscribe(([userPlanData, plans, userData]) => {

      this.plans = plans;

      this.userData = userData;

      this.userPlanData = userPlanData;

    });

    this.getIsYearly$.subscribe( (res: boolean) => this.getIsYearly = res)

  }

  togglePlan() {
    this.store$.dispatch(IsYearly({}))
    this.isTotalYearly = !this.isTotalYearly;
  }

  onSubscription(planId: number) {

    let subscribe: SubscriptionRequest = {planId, period: ''};
    if(this.isTotalYearly)
      subscribe = Object.assign({planId}, {period: 'yearly'});
    else
      subscribe = Object.assign({planId}, {period: 'monthly'});

    this.store$.dispatch(PaddleCheckout(subscribe));

  }

  onCancelSubscription() {

    this.dialog.open(CancelSubscriptionDialogComponent, {
      width: '600px',
      disableClose: true,
      data: {
        paymentId: this.userPlanData.payment_id
      }
    });



  }

}
