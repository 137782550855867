import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { getSubscriptionUser } from '../../core/store/subscription-store/store/subscription.selectors';
import { SubscriptionUser } from '../../core/store/subscription-store/interface/subscription.interface';
import { User } from '../../core/store/user-store/interface/user.interface';
import { userData } from '../../core/store/user-store/store/user.selectors';
import { PaddleCheckout } from '../../core/store/paddle-store/store/paddle.actions';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrl: './statistics.component.sass',
})
export class StatisticsComponent {
  userData$: Observable<User> = this.store$.pipe(select(userData));
  userData!: User;

  getSubscriptionUser$: Observable<SubscriptionUser> = this.store$.pipe(
    select(getSubscriptionUser)
  );

  limit: number = 0;
  maxLimit: number = 0;
  planName: string = 'Free Plan';
  statistic: string = '';
  plan_id: number = 1;
  dailyFilesLimit: number = 0;
  useTrial: number = 1;

  constructor(private router: Router, private store$: Store) {
    combineLatest([this.getSubscriptionUser$, this.userData$]).subscribe(
      ([userPlanData, userData]) => {
        this.userData = userData;

        if (Object.keys(userPlanData).length > 0) {
          const {
            daily_files_limit,
            monthly_rewriting_file,
            package_rewriting_file,
            max_monthly_rewriting_file,
            plan_id,
            plan_name,
            subscription_type,
          } = userPlanData;

          this.dailyFilesLimit = daily_files_limit ?? 0;

          const _limit = monthly_rewriting_file
            ? monthly_rewriting_file
            : package_rewriting_file;
          const _maxLimit = max_monthly_rewriting_file
            ? max_monthly_rewriting_file
            : 0;
          this.plan_id = plan_id;

          if (_limit) this.limit = _limit === -1 ? 0 : _limit;

          if (_maxLimit) this.maxLimit = _maxLimit === -1 ? 0 : _maxLimit;

          if (plan_name) this.planName = plan_name;

          if (subscription_type === 'subscription')
            this.statistic = this.limit + ' / ' + this.maxLimit;
          else this.statistic = String(this.limit);
        }
      }
    );
  }

  onSubscriptionDiscount() {
    this.store$.dispatch(PaddleCheckout({ planId: 4, period: 'monthly' }));
  }
}
