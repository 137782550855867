import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SUBSCRIPTION_FEATURENAME, subscriptionReducer } from './store/subscription.reducer';
import { metaReducersWithLocalStorage } from '../local-storage/localstorage.reducer';
import { SubscriptionEffects } from './store/subscription.effects';
import { EffectsModule } from '@ngrx/effects';
import { RequestInterceptor } from '../interceptors/request.interceptor';
import { StoreModule } from '@ngrx/store';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(
      SUBSCRIPTION_FEATURENAME,
      subscriptionReducer,
      { metaReducers: metaReducersWithLocalStorage }
    ),
    EffectsModule.forFeature([SubscriptionEffects])
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    }
  ]
})

export class SubscriptionModule { }
