// auth.effects.ts

import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AuthGoogle, AuthGoogleFailed, AuthGoogleSuccess, Authorization, AuthorizationFailed, AuthorizationSuccess, AutoAuthorization, AutoAuthorizationFailed, InitLogin, ResetAuth, StartTokenVerification } from './auth.actions';
import { AuthService } from '../services/auth.service';
import { map, switchMap, catchError, tap, takeUntil } from 'rxjs/operators';
import { GoogleData, AuthState } from '../interface/auth.interface';
import { of, timer } from "rxjs";
import { AUTH_FEATURENAME } from "./auth.reducer";
import { GetPlans, ResetPlan } from "../../plan-store/store/plan.actions";
import { GetBilling, GetSettingUser, GetUserData, ResetUser } from "../../user-store/store/user.actions";
import { GetSubscription, ResetSubscription } from "../../subscription-store/store/subscription.actions";
import { BrowserService } from "../../../services/browser/browser.service";
import { Router } from "@angular/router";
import { GetHistoryFile, GetLanguages, ResetApp } from "../../app-store/store/app.actions";
import { ResetPaddle } from "../../paddle-store/store/paddle.actions";
import { ResetReg } from "../../reg-store/store/reg.actions";

@Injectable()
export class AuthEffects {

  logout(): void {
    this.store$.dispatch(ResetApp());
    this.store$.dispatch(ResetAuth());
    this.store$.dispatch(ResetPaddle());
    this.store$.dispatch(ResetPlan());
    this.store$.dispatch(ResetReg());
    this.store$.dispatch(ResetSubscription());
    this.store$.dispatch(ResetUser());
  }

  JWTCheck$ = createEffect(() => this.actions$.pipe(
    ofType(StartTokenVerification),
    tap(() => {
      const authDataString = localStorage.getItem('authorization');
      if (!!authDataString) {
        const authData: AuthState = JSON.parse(authDataString);
        const tokenPayload = this.authService.getJwtPayload(authData.token);

        const checkInterval$ = timer(0, 60000).pipe(
          takeUntil(this.actions$.pipe(ofType(
            StartTokenVerification,
            ResetApp, ResetAuth, ResetPaddle, ResetPlan, ResetReg, ResetSubscription, ResetUser
          ))),
          tap(() => {
            if ((tokenPayload.exp * 1000 - Date.now()) < 0) {
              this.logout();
            }
          })
        );

        checkInterval$.subscribe();
      }
      else
      {
        this.logout();
      }
    })
  ), { dispatch: false });

  RequestData$ = createEffect(() => this.actions$.pipe(
    ofType(AuthorizationSuccess, AutoAuthorization, AuthGoogleSuccess),
    tap(() => {
      this.store$.dispatch(GetPlans());
      this.store$.dispatch(GetSettingUser());
      this.store$.dispatch(GetSubscription());
      this.store$.dispatch(GetHistoryFile({limit: 10}));
      this.store$.dispatch(GetUserData());
      this.store$.dispatch(GetBilling());
      this.store$.dispatch(GetLanguages());
    })
  ), { dispatch: false });

  SaveInfoByUser$ = createEffect(() => this.actions$.pipe(
    ofType(AuthorizationSuccess, AuthGoogleSuccess),
    tap(() => {
      this.browserService.saveInfoByUser();
    })
  ), { dispatch: false });

  Authorization$ = createEffect(() => this.actions$.pipe(
    ofType(Authorization),
    switchMap(login => this.authService.authorizationUser(login)
      .pipe(
        map((auth: AuthState) => {
          if (auth.token && auth.token.trim() !== '')
          {
            this.router.navigate(['/aiworker/statistics']);
            return AuthorizationSuccess(auth);
          }
          else
          {
            return AuthorizationSuccess({
              token: "",
              status: false
            });
          }
        }),
        catchError(error => of(AuthorizationFailed()))
      )
    )
  ));

  SaveAuthDataToLocalStorage$ = createEffect(() => this.actions$.pipe(
    ofType(AuthorizationSuccess, AuthGoogleSuccess),
    tap(({ type, ...authState }) => {
      localStorage.setItem(AUTH_FEATURENAME, JSON.stringify(authState));
      this.store$.dispatch(StartTokenVerification());
    })

  ), { dispatch: false });

  Logout$ = createEffect(() => this.actions$.pipe(
    ofType(ResetApp, ResetAuth, ResetPaddle, ResetPlan, ResetReg, ResetSubscription, ResetUser),
    tap(() => {
      localStorage.setItem(AUTH_FEATURENAME, '');
    })

  ), { dispatch: false });

  InitLogin$ = createEffect(() => this.actions$.pipe(
    ofType(InitLogin),
    map(() => {

      const authDataString = localStorage.getItem(AUTH_FEATURENAME);

      if( !!authDataString )
      {
        const authData: AuthState = JSON.parse(authDataString);
        this.store$.dispatch(StartTokenVerification());
        return AutoAuthorization(authData);
      }
      else
      {
        return AutoAuthorizationFailed();
      }

    })
  ))

  AuthGoogle$ = createEffect(() => this.actions$.pipe(
    ofType(AuthGoogle),
    switchMap((data: GoogleData) => this.authService.authGoogle(data).pipe(
      map((auth: AuthState) => {
        if (auth.token && auth.token.trim() !== '')
        {
          this.router.navigate(['/aiworker/statistics']);
          return AuthGoogleSuccess(auth);
        }
        else
        {
          return AuthGoogleSuccess({
            token: "",
            status: false
          });
        }
      }),
      catchError(error => of(AuthGoogleFailed()))
    ))
  ))

  constructor(
    private actions$: Actions,
    private store$: Store,
    private authService: AuthService,
    private browserService: BrowserService,
    private router: Router
  ){}

}
