import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SubscriptionState } from "../interface/subscription.interface";
import { SUBSCRIPTION_FEATURENAME } from "./subscription.reducer";

const getFeature = createFeatureSelector<SubscriptionState>(SUBSCRIPTION_FEATURENAME);

export const getSubscriptionData = createSelector(
  getFeature,
  state => state
)

export const getSubscriptionUser = createSelector(
  getSubscriptionData,
  subscriptionData => subscriptionData && subscriptionData.user
)
