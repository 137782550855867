import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { of } from "rxjs";
import {
  GetBilling,
  GetBillingFailed,
  GetBillingSuccess,
  GetSettingUser,
  GetSettingUserFailed,
  GetSettingUserSuccess,
  GetUserData,
  GetUserDataFailed,
  GetUserDataSuccess,
  PlanCheck,
  PlanCheckFailed,
  PlanCheckSuccess,
  UploadIco, UploadIcoFailed, UploadIcoSuccess
} from "./user.actions";
import { UserService } from "../services/user.service";
import { Billing, GetSettingUserAnswer, GetUserDataUserAnswer, PlanCheckRequest } from "../interface/user.interface";
import { SubscriptionUser } from "../../subscription-store/interface/subscription.interface";
import {UploadIcoAnswer} from "../interface/uloadico.interface";

@Injectable()
export class UserEffects {

  UploadIco$ = createEffect(() => this.actions$.pipe(
    ofType(UploadIco),
    switchMap((action) => this.userService.uploadIco(action.data).pipe(
      map((file: UploadIcoAnswer) => {
        return UploadIcoSuccess({ ico: file.ico });
      }),
      catchError(error => of(UploadIcoFailed()))
    ))
  ));

  GetSettingUser$ = createEffect(() => this.actions$.pipe(
    ofType(GetSettingUser),
    switchMap(() => this.userService.getSettingUser().pipe(
      map((setting: GetSettingUserAnswer) => {
        return GetSettingUserSuccess(setting.data);
      }),
      catchError(error => of(GetSettingUserFailed()))
    ))
  ))

  GetUserData$ = createEffect(() => this.actions$.pipe(
    ofType(GetUserData),
    switchMap(() => this.userService.getUserData().pipe(
      map((user: GetUserDataUserAnswer) => {
        return GetUserDataSuccess(user.data);
      }),
      catchError(error => of(GetUserDataFailed()))
    ))
  ))

  PlanCheck$ = createEffect(() => this.actions$.pipe(
    ofType(PlanCheck),
    switchMap((data: PlanCheckRequest) => this.userService.planCheck(data).pipe(
      map((plan: SubscriptionUser) => {
        return PlanCheckSuccess(plan);
      }),
      catchError(error => of(PlanCheckFailed()))
    ))
  ));

  GetBilling$ = createEffect(() => this.actions$.pipe(
    ofType(GetBilling),
    switchMap(() => this.userService.getBilling().pipe(
      map((billing: Billing[]) => {
        return GetBillingSuccess({billing});
      }),
      catchError(error => of(GetBillingFailed()))
    ))
  ));

  constructor(
    private actions$: Actions,
    private store$: Store,
    private userService: UserService
  ){}

}
