import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, CanActivateFn } from '@angular/router';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { map, take, switchMap } from 'rxjs/operators';
import { inject } from '@angular/core';
import { getAuthStatus } from './core/store/auth-store/store/auth.selectors';
import { getSubscriptionUser } from './core/store/subscription-store/store/subscription.selectors';

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> => {
  const store$ = inject(Store);
  const router = inject(Router);

  return store$.pipe(
    select(getAuthStatus),
    take(1),
    switchMap(isAuthenticated => {
      if (isAuthenticated) {
        return store$.pipe(
          select(getSubscriptionUser),
          take(1),
          map(user => {
            // console.log('Route Path:', route.routeConfig?.path);
            // console.log('User Data:', user.plan_id);

            if((!user.plan_id || user.plan_id <= 1) && (route.routeConfig?.path)?.includes('support'))
            {
              return false;
            }
            else
            {
              return true;
            }

          })
        );
      } else {
        return new Observable<UrlTree>(observer => {
          observer.next(router.createUrlTree(['/login']));
          observer.complete();
        });
      }
    })
  );
};
