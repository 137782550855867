import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { of } from "rxjs";
import { GetSubscription, GetSubscriptionFailed, GetSubscriptionSuccess, SubscriptionCancel, SubscriptionCancelFailed, SubscriptionCancelSuccess } from "./subscription.actions";
import { SubscriptionService } from "../services/subscription.service";
import { GetSubscriptionAnswer, SubscriptionCancelRequest } from "../interface/subscription.interface";

@Injectable()
export class SubscriptionEffects {


  GetSubscription$ = createEffect(() => this.actions$.pipe(
    ofType(GetSubscription),
    switchMap(() => this.subscriptionService.getSubscription().pipe(
      map((getSubscription: GetSubscriptionAnswer) => {
        return GetSubscriptionSuccess(getSubscription.data);
      }),
      catchError(error => of(GetSubscriptionFailed()))
    ))
  ))

  SubscriptionCancel$ = createEffect(() => this.actions$.pipe(
    ofType(SubscriptionCancel),
    switchMap((data: SubscriptionCancelRequest) => this.subscriptionService.subscriptionCancel(data).pipe(
      map(() => {
        return SubscriptionCancelSuccess();
      }),
      catchError(error => of(SubscriptionCancelFailed()))
    ))
  ))

  SubscriptionCancelSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(SubscriptionCancelSuccess),
    tap(() => {
      this.store$.dispatch(GetSubscription());
    })
  ), { dispatch: false })

  constructor(
    private actions$: Actions,
    private store$: Store,
    private subscriptionService: SubscriptionService
  ){}

}
