import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, mergeMap, throwError } from 'rxjs';
import { GetPaddleDataAnswer, GetPaddleDataRequest } from '../interface/paddle.interface';
import { GET_PLAN } from '../../../global/request/plan';
import { Store, select } from '@ngrx/store';
import { userData } from '../../user-store/store/user.selectors';
import { User } from '../../user-store/interface/user.interface';

declare var Paddle: any;

@Injectable({
  providedIn: 'root'
})
export class PaddleService {

  userData$: Observable<User> = this.store$.pipe(select(userData))
  user!: User;


  constructor(
    private httpClient: HttpClient,
    private store$: Store
  )
  {
    this.userData$.subscribe(user => this.user = user);
  }

  checkoutOpen(plan: GetPaddleDataAnswer): void {

    let priceId = null;
    let discountId = null;
    if(plan.period === 'monthly')
    {
      priceId = plan.data.monthly_paddle_id
      discountId = plan.data.monthly_discount_paddle_id
    }
    else if (plan.period === 'yearly')
    {
      priceId = plan.data.yearly_paddle_id
      discountId = plan.data.yearly_discount_paddle_id
    }

    Paddle.Checkout.open({
      items: [
        {
          priceId
        }
      ],
      customer: {
        email: this.user.email
      },
      discountId,
      settings: {
        theme: "dark",
        locale: "en"
      },
      customData: JSON.stringify({
        planId: plan.data.plan_id,
        userId: this.user.user_id,
        email: this.user.email
      })
    });



  }

  createCheckout(data: GetPaddleDataRequest): Observable<GetPaddleDataAnswer> {

    const {type, period, ...req}: any = data;

    return this.httpClient.post<GetPaddleDataAnswer>(GET_PLAN, req)
    .pipe(
      mergeMap(res => {
        if ('error' in res) {
          return throwError(res);
        }
        return [res];
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

}
