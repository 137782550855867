<mat-card>
  <mat-card-content>
    <form [formGroup]="supportForm" (ngSubmit)="onSendSupportMessage()">

      <mat-card-title class="head-support">
        <h2>Support</h2>
      </mat-card-title>

      <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email">
      </mat-form-field>

      <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Message</mat-label>
        <textarea matInput formControlName="message" rows="6" maxlength="2500"></textarea>
        <mat-hint align="end">{{ supportForm.get('message')?.value?.length || 0 }}/2500</mat-hint>
      </mat-form-field>

      <button mat-raised-button color="primary" type="submit" [disabled]="!supportForm.valid">Send</button>
    </form>
  </mat-card-content>
</mat-card>
