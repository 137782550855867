<div mat-dialog-content class="dialog-content">
  <i class="fa fa-exclamation-triangle dialog-icon"></i>
  <h2 mat-dialog-title>Invalid File Format</h2>
  <p>Invalid file format or size. Please adhere to the following requirements:</p>
  <ul>
    <li>Allowed formats: <strong>.DOCX, .PDF, .TXT</strong></li>
    <li>Maximum file size: <strong>10 MB</strong></li>
  </ul>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-button mat-dialog-close class="dialog-button">I Understand</button>
</div>
