import { createFeatureSelector, createSelector } from "@ngrx/store";
import { REG_FEATURENAME } from './reg.reducer';
import { RegState } from '../interface/reg.interface';

const getRegFeature = createFeatureSelector<RegState>(REG_FEATURENAME);

export const getRegData = createSelector(
  getRegFeature,
  state => state
)

export const getRegStatus = createSelector(
  getRegData,
  regData => regData.status
)

export const getExistsEmailStatus = createSelector(
  getRegData,
  existsData => existsData.existsEmail
)

export const getExistsLoginStatus = createSelector(
  getRegData,
  existsData => existsData.existsLogin
)
