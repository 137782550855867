import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { PaddleCheckout } from '../../../core/store/paddle-store/store/paddle.actions';

@Component({
  selector: 'app-trial-exceeding-limit-dialog',
  templateUrl: './trial-exceeding-limit-dialog.component.html',
  styleUrl: './trial-exceeding-limit-dialog.component.sass'
})
export class TrialExceedingLimitDialogComponent {

  constructor(
    private store$: Store,
    public dialogRef: MatDialogRef<TrialExceedingLimitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { planId: number }
  ){}

  onBuyPlan(): void {
    this.store$.dispatch(PaddleCheckout({ planId: this.data.planId, period: "monthly" }));
    this.dialogRef.close();
  }


}
