import { createFeatureSelector, createSelector } from "@ngrx/store";
import { APP_FEATURENAME } from "./app.reducer";
import { AppState } from "../interface/app.interface";

const getFeature = createFeatureSelector<AppState>(APP_FEATURENAME);

export const appData = createSelector(
  getFeature,
  state => state
)

export const getLanguages = createSelector(
  appData,
  appData => appData.languages && appData.languages
)

export const getPromoFile = createSelector(
  appData,
  appData => appData.file.promoFile
)

export const getHistoryFileStore = createSelector(
  appData,
  appData => appData.list.files
)
