import { createFeatureSelector, createSelector } from "@ngrx/store";
import { PlanState } from "../interface/plan.interface";
import { PLAN_FEATURENAME } from "./plan.reducer";

const getFeature = createFeatureSelector<PlanState>(PLAN_FEATURENAME);

export const plansData = createSelector(
  getFeature,
  state => state
)

export const getPlans = createSelector(
  plansData,
  planData => planData && planData.plans
)

export const getIsYearly = createSelector(
  plansData,
  planData => planData && planData.isYearly
)
