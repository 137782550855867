import { isPlatformBrowser } from "@angular/common";
import { ActionReducer, MetaReducer } from "@ngrx/store";
import { AUTH_FEATURENAME } from "../auth-store/store/auth.reducer";
import { localStorageSync } from "ngrx-store-localstorage";
import { Inject, PLATFORM_ID } from "@angular/core";

function loggerMetaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return function(state, action) {
    const nextState = reducer(state, action);
    return nextState;
  };
}

export function localStorageSyncReducer(platformId: Object): MetaReducer<any> {
  return (reducer: ActionReducer<any>): ActionReducer<any> => {
    return isPlatformBrowser(platformId) ? localStorageSync({
      keys: [AUTH_FEATURENAME],
      rehydrate: true
    })(reducer) : reducer;
  };
}

export const metaReducersWithLocalStorage: Array<MetaReducer<any, any>> = [
  loggerMetaReducer,
  localStorageSyncReducer(Inject(PLATFORM_ID))
];
