<div class="auth-container">
  <form [formGroup]="formReg" (ngSubmit)="onReg()">
    <mat-card>
      <mat-card-header>
        <div mat-card-avatar class="header-image">
          <a href="https://newtxt.io/"
            ><img src="assets/newtxt_white.webp" alt="NewTXT"
          /></a>
        </div>
      </mat-card-header>
    </mat-card>

    <mat-card>
      <mat-card-header>
        <div class="header-text">
          <h2 mat-card-title>Registration</h2>
        </div>
      </mat-card-header>
      <mat-card-content>
        <ng-container *ngIf="showRegisterEmail">
          <mat-form-field>
            <mat-label>Login</mat-label>
            <input matInput formControlName="login" />
            <mat-error *ngIf="formReg.get('login')?.errors?.['required']">
              Login is required.
            </mat-error>
            <mat-error *ngIf="formReg.get('login')?.errors?.['loginExists']">
              This login already exists.
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput formControlName="first_name" />
            <mat-error *ngIf="formReg.get('first_name')?.errors?.['required']">
              Name is required.
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="last_name" />
            <mat-error *ngIf="formReg.get('last_name')?.errors?.['required']">
              Last name is required.
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" />
            <mat-error *ngIf="formReg.get('email')?.errors?.['required']">
              Email is required.
            </mat-error>
            <mat-error *ngIf="formReg.get('email')?.errors?.['email']">
              Please enter a valid email address.
            </mat-error>
            <mat-error *ngIf="formReg.get('email')?.errors?.['emailExists']">
              This login already exists.
            </mat-error>
          </mat-form-field>

          <div formGroupName="passwords">
            <mat-form-field>
              <mat-label>Password</mat-label>
              <input
                matInput
                [type]="hide ? 'password' : 'text'"
                formControlName="password"
              />
              <mat-icon matSuffix (click)="hide = !hide">{{
                hide ? "visibility_off" : "visibility"
              }}</mat-icon>
              <mat-error
                *ngIf="formReg.get('passwords')?.get('password')?.errors?.['required']"
              >
                Password is required.
              </mat-error>
              <mat-error
                *ngIf="formReg.get('passwords')?.get('password')?.errors?.['minlength']"
              >
                Password must be at least 6 characters.
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Confirm Password</mat-label>
              <input
                matInput
                [type]="hide ? 'password' : 'text'"
                formControlName="confirmPassword"
              />
              <mat-icon matSuffix (click)="hide = !hide">{{
                hide ? "visibility_off" : "visibility"
              }}</mat-icon>
              <mat-error
                *ngIf="formReg.get('passwords')?.get('confirmPassword')?.errors?.['required']"
              >
                Confirming password is required.
              </mat-error>
              <mat-error
                *ngIf="formReg.get('passwords')?.hasError('passwordMismatch')"
              >
                Passwords do not match.
              </mat-error>
            </mat-form-field>
          </div>
        </ng-container>

        <div class="block-button">
          <!-- <button type="button" mat-button class="google-signin-btn" (click)="onSignUpWithGoogle()">
            <mat-icon>account_circle</mat-icon> Sign up with Google
          </button> -->

          <div
            #googleBtn
            id="google-register-btn"
            class="g_id_signin"
            data-type="standard"
            data-theme="outline"
            data-text="signup_with"
            data-shape="rectangular"
            data-width="50"
          ></div>

          <button
            type="submit"
            mat-raised-button
            color="primary"
            *ngIf="!showRegisterEmail"
            (click)="onShowRegisterEmail()"
          >
            Sign up with email
          </button>

          <button
            *ngIf="showRegisterEmail"
            type="submit"
            mat-raised-button
            color="primary"
            [disabled]="!formReg.valid"
          >
            Register
          </button>

          <button
            type="button"
            mat-raised-button
            color="primary"
            (click)="onShowLogIn()"
          >
            Back to log in
          </button>
        </div>
      </mat-card-content>
    </mat-card>

    <app-info-policy></app-info-policy>
  </form>

  <div id="login-footer">
    <app-footer-company></app-footer-company>
  </div>
</div>
