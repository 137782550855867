// auth.selectors.ts
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AUTH_FEATURENAME } from './auth.reducer';
import { AuthState } from '../interface/auth.interface';

const getAuthFeature = createFeatureSelector<AuthState>(AUTH_FEATURENAME);

export const getAuthData = createSelector(
  getAuthFeature,
  state => state
)

export const getToken = createSelector(
  getAuthData,
  authData => authData && authData.token
)

export const getAuthStatus = createSelector(
  getAuthData,
  authData => authData && authData.status
)
