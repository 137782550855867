<div class="auth-container">

  <form [formGroup]="formAuth" (submit)="onLogIn()">

    <mat-card>
      <mat-card-header>
        <div mat-card-avatar class="header-image">
          <a href="https://newtxt.io/"><img src="assets/newtxt_white.webp" alt="NewTXT"></a>
        </div>
      </mat-card-header>
    </mat-card>

    <mat-card>
      <mat-card-header>
        <div class="header-text">
          <h2 mat-card-title>Authorization</h2>
        </div>
      </mat-card-header>
      <mat-card-content>
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input matInput formControlName="email">
          <mat-error *ngIf="formAuth.get('email')?.errors?.['required']">
            Email is required.
          </mat-error>
          <mat-error *ngIf="formAuth.get('email')?.errors?.['email']">
            Please enter a valid email.
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Password</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error *ngIf="formAuth.get('password')?.errors?.['required']">
            Password is required.
          </mat-error>
          <mat-error *ngIf="formAuth.get('password')?.errors?.['minlength']">
            Password must be at least 6 characters.
          </mat-error>
        </mat-form-field>
        <div class="block-button">
          <button
            type="submit"
            mat-raised-button
            color="primary"
            [disabled]="!formAuth.valid"
          >Log In</button>
          <button
            type="button"
            mat-raised-button
            color="primary"
            (click)="onLogUp()"
          >Sing Up</button>

          <div #googleBtn
            id="google-signin-btn"
            class="g_id_signin"
            data-type="standard"
            data-theme="outline"
            data-text="sign_in_with"
            data-shape="rectangular"
            data-auto_prompt="true"
            data-width="50"
          ></div>

            <!--
            <button type="button" mat-button class="apple-signin-btn" (click)="onSignUpWithApple()">
              <mat-icon>apple</mat-icon> Sign up with Apple
            </button> -->

        </div>
      </mat-card-content>

      <mat-card-actions>
        <button type="button" routerLink="/account-recovery" mat-button color="warn">Forgot Password</button>
      </mat-card-actions>

    </mat-card>

    <app-info-policy></app-info-policy>

  </form>

  <div id="login-footer">
    <app-footer-company></app-footer-company>
  </div>


</div>


