<h1 mat-dialog-title>Dear client</h1>
<div mat-dialog-content>
  <p>{{message}}</p>
</div>

<ng-container *ngIf="action === 'forgot_account'; else sing_up">
  <div mat-dialog-actions align="end">
    <button mat-flat-button [mat-dialog-close]="true" color="primary" routerLink="/account-recovery">Recover account</button>
  </div>
</ng-container>

<ng-template #sing_up>
  <div mat-dialog-actions align="end">
    <button mat-flat-button [mat-dialog-close]="true" color="primary" routerLink="/register">Register now</button>
  </div>
</ng-template>



