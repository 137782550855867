import { Injectable } from '@angular/core';
import { GET_SUBSCRIBE_USER, SUBSCRIBE_CANCEL } from '../../../global/request/subscription';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { GetSubscriptionAnswer, SubscriptionAnswer, SubscriptionCancelRequest, SubscriptionRequest } from '../interface/subscription.interface';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(
    private httpClient: HttpClient
  ) { }


  getSubscription(): Observable<GetSubscriptionAnswer> {

    return this.httpClient.post<GetSubscriptionAnswer>(GET_SUBSCRIBE_USER, {})
    .pipe(
      map( (res) => {
        return res;
      })
    );

  }

  subscriptionCancel(data: SubscriptionCancelRequest): Observable<void> {

    const {type, ...req}: any = data;

    return this.httpClient.post<void>(SUBSCRIBE_CANCEL, req)
    .pipe(
      map( (res) => {
        return res;
      })
    );

  }


}
