<div class="flex-container">
  <div class="flex-item">

    <ng-container *ngIf="((plan_id <= 1 || !plan_id) && userData.use_trial === 0); else plans">

      <div class="stat-content">
        <div class="container-free">

          <div class="title-plan">YOU ARE NOW USING A</div>

          <div class="now-free-plan">
            <span class="free-plan-color">FREE</span>
            <span class="free-plan">PLAN</span>
          </div>

          <div class="box-info-on-upgrade">

            <div class="info-free-plan">
              <div class="info-title-free">
                <h2>Your current plan:</h2>
              </div>
              <div class="info-list-free">
                <ul class="custom-list">
                  <li><p><span>Daily files limit:</span> {{-(dailyFilesLimit - 5)}}/5 </p></li>
                  <li><p><span>Words pre file:</span> 5000</p></li>
                  <li><p><span>File sizes:</span> 10mb</p></li>
                </ul>
              </div>
            </div>

            <div class="upgrade-plan">

              <div class="upgrade-title">
                <h2>Upgrade to Premium:</h2>
              </div>

              <div class="upgrade-list">
                <ul class="custom-list">
                  <li><p><span>Daily files limit:</span> 200</p></li>
                  <li><p><span>Words per file:</span> Unlimited</p></li>
                  <li><p><span>File sizes:</span> Unlimited</p></li>
                  <a routerLink="/aiworker/subscription"><span>and even more possibilities...</span></a>
                </ul>
              </div>

              <div class="upgrade-price">
                <div class="new-price">
                  <p>$24.99<span>$39.99</span></p>
                </div>
              </div>

              <div class="upgrade-action">
                <button type="button" (click)="onSubscriptionDiscount()">Upgrade <i class="fas fa-arrow-right"></i></button>
              </div>

              <div class="upgrade-info-link">
                <a routerLink="/aiworker/subscription">Upgrade your plan now and save</a>&ensp;<span class="new-plan-discount">37.51%</span>
              </div>

            </div>

          </div>

        </div>
      </div>

    </ng-container>

    <ng-template #plans>

      <mat-card>
        <mat-card-title>
          <h2>{{ planName }}</h2>
        </mat-card-title>
      </mat-card>

      <div class="stat-content">

        <div class="box-info">
          <div class="name-status">
            <span>Available words</span>
          </div>
          <div class="info-status">
            <span><b>{{ maxLimit }}</b></span>
          </div>
        </div>

        <div class="box-info">
          <div class="name-status">
            <span>Words left</span>
          </div>
          <div class="info-status">
            <span><b>{{ limit }}</b></span>
          </div>
        </div>

      </div>

    </ng-template>


    <!-- <mat-progress-bar
      mode="determinate"
      [value]="(limit / maxLimit) * 100"
      class="progress-bar"
    ></mat-progress-bar> -->

  </div>
</div>
