import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { filter, Observable } from 'rxjs';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { getAuthStatus } from './core/store/auth-store/store/auth.selectors';
import { InitLogin } from './core/store/auth-store/store/auth.actions';
import { GetLanguages } from './core/store/app-store/store/app.actions';
import { UserService } from './core/store/user-store/services/user.service';
import { getSubscriptionUser } from './core/store/subscription-store/store/subscription.selectors';
import { SubscriptionUser } from './core/store/subscription-store/interface/subscription.interface';

declare var Paddle: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.media.sass', './app.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  logIn = false;

  getAuthStatus$: Observable<boolean> = this.store$.pipe(select(getAuthStatus));
  getAuthStatus: boolean = false;

  userPlanData$: Observable<SubscriptionUser> = this.store$.pipe(select(getSubscriptionUser));
  userPlan!: SubscriptionUser;

  constructor(
    private router: Router,
    private store$: Store,
    private userService: UserService
  ) {

    const domain = window.location.hostname;

    this.userPlanData$.subscribe(userPlan => this.userPlan = userPlan);

    this.store$.dispatch(InitLogin());

    this.getAuthStatus$.subscribe(getAuthStatus => {
      this.getAuthStatus = getAuthStatus;
      if (getAuthStatus) {
        this.logIn = true;
      } else {
        this.logIn = false;
      }
    });

    this.store$.dispatch(GetLanguages());

    if (domain === 'localhost' || domain === 'dev-app.newtxt.io') {

      console.warn('Start test paddle')

      Paddle.Environment.set('sandbox');
      Paddle.Initialize({
        token: 'test_7939c932e1a38e7732d0b0c08bb',
        eventCallback: (data: any) => {
          if (data.name === "checkout.completed") {
            this.userService.startPlanCheck({ planId: this.userPlan.plan_id ?? 1, trialing: this.userPlan.trialing ?? 0 });
          }
        }
      });
      Paddle.Setup({ vendor: 17114 });
    } else {
      Paddle.Environment.set('production');
      Paddle.Initialize({
        token: 'live_c7545474188ac3da172766f8253',
        eventCallback: (data: any) => {
          console.log(data);

          if (data.name === "checkout.completed") {
            this.userService.startPlanCheck({ planId: this.userPlan.plan_id ?? 1, trialing: this.userPlan.trialing ?? 0 });
          }
        }
      });
      Paddle.Setup({ vendor: 183966 });
    }
  }

  ngOnInit() {
    this.router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd)
    ).subscribe((event: Event) => {
      const navEndEvent = event as NavigationEnd;
      if (this.getAuthStatus) {
        if (navEndEvent.url.includes('/aiworker')) {
          // No need to navigate if the user is already on the correct URL
          return;
        } else {
          this.router.navigate(['/aiworker/statistics']);
        }
      }
    });
  }
}
