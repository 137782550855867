import { Component, OnInit, HostListener, ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { isPlatformBrowser } from '@angular/common';
import { Store, select } from '@ngrx/store';
import { Billing, UserState } from '../../core/store/user-store/interface/user.interface';
import { userBillingData } from '../../core/store/user-store/store/user.selectors';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.sass']
})
export class BillingComponent implements OnInit {
  displayedColumns: string[] = ['purchaseDate', 'expirationDate', 'productName', 'status'];
  dataSource = new MatTableDataSource<Billing>();

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private store: Store<UserState>
  ) {}

  ngOnInit() {
    this.store.pipe(select(userBillingData)).subscribe((billingData: Billing[]) => {
      this.dataSource.data = billingData;
    });

    this.dataSource.paginator = this.paginator;

    if (isPlatformBrowser(this.platformId)) {
      this.adjustColumns(window.innerWidth);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.adjustColumns(event.target.innerWidth);
    }
  }

  private adjustColumns(width: number) {
    if (width < 600) {
      this.displayedColumns = ['purchaseDate', 'expirationDate', 'productName', 'status'];
    } else {
      this.displayedColumns = ['purchaseDate', 'expirationDate', 'productName', 'status'];
    }
  }
}
