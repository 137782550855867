import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-loading-payment-dialog',
  templateUrl: './error-loading-payment-dialog.component.html',
  styleUrl: './error-loading-payment-dialog.component.sass'
})
export class ErrorLoadingPaymentDialogComponent {

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<ErrorLoadingPaymentDialogComponent>
  ) {}

  goToSupport(): void {
    this.dialogRef.close();
    this.router.navigate(['/aiworker/support']);
  }

}
