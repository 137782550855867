
import { environment } from '../../../../environments/environment';

console.log(environment);

const HTTPS = "https"
const API_DOMAIN = environment.DEV_DOMAIN;

const API = ""

const REQUEST = `${HTTPS}://${API_DOMAIN}`

export const API_SERVER = `${REQUEST}/${API}`

