import { Component, OnInit, OnDestroy, ElementRef, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { UniqueUserValidator } from '../../../core/validator/unique-user.validator';
import { MatDialog } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { debounceTime, distinctUntilChanged, interval, Subject, Subscription, switchMap, take, takeUntil, tap } from 'rxjs';
import { FindUser, FindUserSuccess, FindUserFailed, GetCode, GetCodeSuccess, VerificationCode, VerificationCodeSuccess, AccountRecovery, AccountRecoverySuccess } from '../../../core/store/reg-store/store/reg.actions';
import { getExistsLoginStatus } from '../../../core/store/reg-store/store/reg.selectors';
import { passwordMatchValidator } from '../../../core/validator/password-match.validator';

@Component({
  selector: 'app-account-recovery',
  templateUrl: './account-recovery.component.html',
  styleUrls: ['./account-recovery.component.sass']
})
export class AccountRecoveryComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChildren('codeInput') codeInputs!: QueryList<ElementRef>;

  private destroy$ = new Subject<void>();

  hide = true;
  hideConfirm = true;

  resendTimer: number = 60;
  canResend: boolean = true;
  resendSubscription?: Subscription;

  formVerificationUser: FormGroup;
  formCode: FormGroup;
  formRecover: FormGroup;
  emailSendCode: string = '';

  onFormLogin: boolean = true;
  onFormCode: boolean = false;
  onFormRecover: boolean = false;

  userLogin: {email?: string, login?: string} = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store$: Store,
    private formBuilder: FormBuilder,
    private uniqueUserValidator: UniqueUserValidator,
    private dialog: MatDialog,
    private actions$: Actions
  ) {
    this.formVerificationUser = this.formBuilder.group({
      user: ['', [Validators.required], [this.uniqueUserValidator.findUser()]]
    });
    this.formCode = this.formBuilder.group({
      code0: ['', [Validators.required, Validators.pattern('^\\d+$')]],
      code1: ['', [Validators.required, Validators.pattern('^\\d+$')]],
      code2: ['', [Validators.required, Validators.pattern('^\\d+$')]],
      code3: ['', [Validators.required, Validators.pattern('^\\d+$')]],
      code4: ['', [Validators.required, Validators.pattern('^\\d+$')]],
      code5: ['', [Validators.required, Validators.pattern('^\\d+$')]],
      code6: ['', [Validators.required, Validators.pattern('^\\d+$')]],
      code7: ['', [Validators.required, Validators.pattern('^\\d+$')]],
      code8: ['', [Validators.required, Validators.pattern('^\\d+$')]]
    });
    this.formRecover = this.formBuilder.group({
      password: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.pattern(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+/)
      ]],
      confirmPassword: ['', Validators.required]
    }, { validators: passwordMatchValidator });
  }

  ngOnInit(): void {
    this.setupUserInputListener();
    this.watchCodeSuccess();
    this.watchVerificationCodeSuccess();
    this.watchAccountRecoverySuccess();
  }

  ngAfterViewInit(): void {
    this.codeInputs.changes.subscribe(() => {
      this.codeInputs.toArray().forEach((input, index) => {
        input.nativeElement.addEventListener('input', (event: Event) => this.onInput(event, index));
      });
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.resendSubscription?.unsubscribe();
  }

  private watchCodeSuccess(): void {
    this.actions$
      .pipe(
        ofType(GetCodeSuccess),
        takeUntil(this.destroy$)
      )
      .subscribe(action => {
        console.log(action);
        this.emailSendCode = action.email;
        this.onFormLogin = false;
        this.onFormCode = true;
      });
  }

  private watchVerificationCodeSuccess(): void {
    this.actions$
      .pipe(
        ofType(VerificationCodeSuccess),
        takeUntil(this.destroy$)
      )
      .subscribe(action => {
        this.onFormLogin = false;
        this.onFormCode = false;
        this.onFormRecover = true;
      });
  }

  private watchAccountRecoverySuccess(): void {
    this.actions$
      .pipe(
        ofType(AccountRecoverySuccess),
        takeUntil(this.destroy$)
      )
      .subscribe(action => {
        this.router.navigate(['/login']);
      });
  }

  onResendCode(): void {
    if (this.canResend) {
      this.canResend = false;
      this.resendTimer = 60;

      this.sendOnGetCode()

      this.resendSubscription = interval(1000).pipe(take(60)).subscribe({
        next: () => this.resendTimer--,
        complete: () => {
          this.canResend = true;
        }
      });
    }
  }

  private setupUserInputListener(): void {
    const loginControl = this.formVerificationUser.get('user');
    if (loginControl) {
      loginControl.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          takeUntil(this.destroy$),
          switchMap(value => {

            const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
            this.userLogin = isEmail ? { email: value } : { login: value };

            this.store$.dispatch(FindUser(this.userLogin));
            return this.actions$.pipe(
              ofType(FindUserSuccess, FindUserFailed),
              takeUntil(this.destroy$),
              tap(action => {
                if (action.type === FindUserSuccess.type) {
                  this.clearFormControlErrors();
                } else if (action.type === FindUserFailed.type) {
                  this.setFormControlErrors();
                }
              })
            );
          })
        ).subscribe();
    }
  }

  private clearFormControlErrors(): void {
    const loginControl = this.formVerificationUser.get('user');
    if (loginControl) {
      loginControl.setErrors(null);
    }
  }

  private setFormControlErrors(): void {
    const loginControl = this.formVerificationUser.get('user');
    if (loginControl) {
      loginControl.setErrors({ userExists: true });
    }
  }

  onGetCode(): void {
    if (this.formVerificationUser.valid && this.canResend) {
      this.canResend = false;
      this.resendTimer = 60;

      this.onFormCode = !!this.onFormCode;
      this.sendOnGetCode()

      this.resendSubscription = interval(1000).pipe(take(60)).subscribe({
        next: () => this.resendTimer--,
        complete: () => {
          this.canResend = true;
        }
      });
    }
  }

  sendOnGetCode() {
    const data = (Object.keys(this.userLogin).includes('email')) ? { email: this.userLogin.email } : {login: this.userLogin.login};
    this.store$.dispatch(GetCode(data));
  }

  onConfirmCode(): void {

    if (!!this.emailSendCode && this.formCode.valid) {
      const code = Object.values(this.formCode.value).join('');
      this.store$.dispatch(VerificationCode({email: this.emailSendCode, code }));
    }

  }

  onRecover(): void {
    if (this.formRecover.valid) {
      const passwordControl = this.formRecover.get('password');

      if (passwordControl) {
        const passwordValue = passwordControl.value;
        this.store$.dispatch(AccountRecovery({ email: this.emailSendCode, password: passwordValue }));
      }
    }
  }

  onShowLogIn(): void {
    this.router.navigate(['/login']);
  }

  onInput(event: Event, index: number): void {
    const input = event.target as HTMLInputElement;
    if (input.value.length === 1 && index < 8) {
      const nextInput = this.codeInputs.toArray()[index + 1];
      nextInput.nativeElement.focus();
    } else if (input.value.length === 0 && index > 0) {
      const prevInput = this.codeInputs.toArray()[index - 1];
      prevInput.nativeElement.focus();
    }
  }
}
