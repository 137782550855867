import { Component } from '@angular/core';

@Component({
  selector: 'app-service-works-dialog',
  templateUrl: './service-works-dialog.component.html',
  styleUrl: './service-works-dialog.component.sass'
})
export class ServiceWorksDialogComponent {

}
