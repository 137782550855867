import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, take } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { getLanguages, getPromoFile } from '../../../core/store/app-store/store/app.selectors';
import { Languages } from '../../../core/store/app-store/interface/languages.interface';
import { CustomDialogComponent } from '../../dialog/custom-dialog/custom-dialog.component';
import { ReceivingFile, ReceivingFileSuccess } from '../../../core/store/app-store/store/app.actions';
import { Router } from '@angular/router';
import { PromoFile } from '../../../core/store/app-store/interface/receivingfile.interface';

@Component({
  selector: 'app-expon-file-rewrite',
  templateUrl: './expon-file-rewrite.component.html',
  styleUrls: ['./expon-file-rewrite.component.sass']
})
export class ExponFileRewriteComponent {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  form: FormGroup;
  languages$: Observable<Languages[]> = this.store$.pipe(select(getLanguages));
  isDraggingOver: boolean = false;
  uploadedFileName: string | null = null;
  isUploading: boolean = false;
  uploadProgress: number = 0;
  maxFileSize = 10 * 1024 * 1024;

  validFileExtensions = ['.docx', '.pdf', '.txt'];

  currentStatus: string = '';

  promoFile$: Observable<PromoFile> = this.store$.pipe(select(getPromoFile));

  constructor(
    private fb: FormBuilder,
    private store$: Store,
    private dialog: MatDialog,
    private router: Router,
    private dialogRef: MatDialogRef<ExponFileRewriteComponent>
  ) {
    this.form = this.fb.group({
      file: [null, Validators.required],
      language: [{ value: '', disabled: true }, Validators.required]
    });
  }

  get acceptedFileTypes(): string {
    return this.validFileExtensions.join(',');
  }


  ngOnInit() {
    this.promoFile$.subscribe(promoFile => {
      if (promoFile.status)
      {
        this.dialogRef.close();
      }
    });
  }

  isValidFileFormat(fileName: string): boolean {
    return this.validFileExtensions.some(ext => fileName.toLowerCase().endsWith(ext));
  }

  isValidFileSize(fileSize: number): boolean {
    return fileSize <= this.maxFileSize;
  }

  openFileSelector() {
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length) {
      const file = input.files[0];

      if (this.isValidFileFormat(file.name) && this.isValidFileSize(file.size)) {
        this.uploadedFileName = file.name;
        this.form.patchValue({ file: file });
        this.form.get('language')?.enable();
        this.simulateUploadProgress();
      } else {
        this.dialog.open(CustomDialogComponent, {
          disableClose: true
        });
      }
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDraggingOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.isDraggingOver = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.isDraggingOver = false;

    if (event.dataTransfer && event.dataTransfer.files.length) {
      const file = event.dataTransfer.files[0];
      if (this.isValidFileFormat(file.name) && this.isValidFileSize(file.size)) {
        this.uploadedFileName = file.name;
        this.form.patchValue({ file: file });
        this.form.get('language')?.enable();
        this.simulateUploadProgress();
      } else {
        this.dialog.open(CustomDialogComponent, {
          disableClose: true
        });
      }
    }
  }

  removeFile() {
    this.uploadedFileName = null;
    this.form.patchValue({ file: null });
    this.form.get('language')?.disable();
    this.isUploading = false;
  }

  simulateUploadProgress() {
    this.isUploading = true;
    this.uploadProgress = 0;

    const interval = setInterval(() => {
      this.uploadProgress += 3.33;

      if (this.uploadProgress < 30) {
        this.currentStatus = 'Uploading file...';
      } else if (this.uploadProgress < 60) {
        this.currentStatus = 'Reading file...';
      } else if (this.uploadProgress < 99) {
        this.currentStatus = 'Processing text...';
      } else {
        this.currentStatus = ''
      }

      if (this.uploadProgress >= 100) {
        clearInterval(interval);
        this.isUploading = false;
      }
    }, 1000);
  }

  onSubmit() {

    if (this.form.valid)
    {
      const formData: FormData = new FormData();
      const fileToUpload = this.form.get('file')?.value;
      const languageId = this.form.get('language')?.value;
      formData.append('file', fileToUpload, fileToUpload.name);
      formData.append('languageId', languageId.toString());

      this.store$.dispatch(ReceivingFile({promoFile: formData, languageId}));
    }

  }

}
