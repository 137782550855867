import { createReducer, on } from '@ngrx/store';
import { UserState } from '../interface/user.interface';
import { GetBillingSuccess, GetSettingUserSuccess, GetUserDataSuccess, PlanCheckSuccess, ResetUser } from './user.actions';

export const USER_FEATURENAME = "user";

const userState: Partial<UserState> = {
  user: {
    user_id: 0,
    email: "",
    use_trial: 0
  },
  setting: {
    is_admin: 0,
    avatar: "",
    last_name: "",
    first_name: "",
    login: "",
    email: "",
    account_confirmation: 0,
    email_notifications: 0,
    auto_send_history: 0,
    deleted: 0,
    petuh_ebani: 0
  },
  billing: []
}

export const userReducer = createReducer(
  userState,

  on(GetSettingUserSuccess, (state, setting) => ({
    ...state,
    setting
  })),

  on(GetUserDataSuccess, (state, user) => ({
    ...state,
    user
  })),

  on(GetBillingSuccess, (state, {billing}) => ({
    ...state,
    billing
  })),

  on(ResetUser, (state) => ({
    ...state,
    ...userState
  }))

);
