import { Component, Input } from '@angular/core';
import { HistoryFile } from '../../../core/store/app-store/interface/historyfile.interface';
import { Store, select } from '@ngrx/store';
import { DeleteHistory, DeleteHistorySuccess, DownloadFile, GetHistoryFile, HumanizedFile, HumanizedFileSuccess, RewriteFile, RewriteFileSuccess } from '../../../core/store/app-store/store/app.actions';
import { Observable, of, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { PaddleCheckout } from '../../../core/store/paddle-store/store/paddle.actions';
import { getSubscriptionUser } from '../../../core/store/subscription-store/store/subscription.selectors';
import { SubscriptionUser } from '../../../core/store/subscription-store/interface/subscription.interface';
import { GetSubscription, GetSubscriptionSuccess } from '../../../core/store/subscription-store/store/subscription.actions';
import { MatDialog } from '@angular/material/dialog';
import { ExceedingLimitDialogComponent } from '../../dialog/exceeding-limit-dialog/exceeding-limit-dialog.component';
import { TrialExceedingLimitDialogComponent } from '../../dialog/trial-exceeding-limit-dialog/trial-exceeding-limit-dialog.component';
import { AppService } from '../../../core/store/app-store/services/app.service';



@Component({
  selector: 'app-file-panel',
  templateUrl: './file-panel.component.html',
  styleUrl: './file-panel.component.sass'
})
export class FilePanelComponent {

  @Input() history!: HistoryFile;

  @Input() formattedDate!: string;
  @Input() searchName!: string;
  @Input() itemsPerPage!: number;
  @Input() pageIndex!: number;

  private destroy$ = new Subject<void>();

  userSubscription$: Observable<SubscriptionUser> = this.store$.pipe(select(getSubscriptionUser))
  userSubscription!: SubscriptionUser;

  constructor(
    private router: Router,
    private http: HttpClient,
    private store$: Store,
    private actions$: Actions,
    private dialog: MatDialog,
    private appService: AppService
  )
  {
    this.userSubscription$.subscribe( (res: SubscriptionUser) => this.userSubscription = res )
  }

  onTrash(history_id: number) {
    this.store$.dispatch(DeleteHistory({history_id}));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onDownloadFile(historyId: number, filename: string) {
    this.store$.dispatch(DownloadFile({historyId, filename}));
  }

  onRewrite(historyId: number){

    this.store$.dispatch(GetSubscription());

    this.actions$.pipe(
      ofType(GetSubscriptionSuccess),
      take(1),
      tap(() => {

        this.callTool(historyId, 'rewrite')

      })
    ).subscribe();

  }

  onHumanized(historyId: number){

    this.store$.dispatch(GetSubscription());

    this.actions$.pipe(
      ofType(GetSubscriptionSuccess),
      take(1),
      tap(() => {

        this.callTool(historyId, 'humanized')

      })
    ).subscribe();


  }

  callTool(historyId: number, type: string ): void {

    console.log(this.userSubscription);

    let _stop = false;

    if(this.userSubscription.trialing === 1)
    {
      const usedPercentage = (this.userSubscription.max_monthly_rewriting_file - this.userSubscription.monthly_rewriting_file) / this.userSubscription.max_monthly_rewriting_file * 100;

      if (usedPercentage >= 10)
      {

        this.dialog.open(TrialExceedingLimitDialogComponent, {
          width: '600px',
          data: {
            planId: this.userSubscription.plan_id
          }
        });

        _stop = true;
      }

    }

    if( !_stop )
    {

      if(
        this.userSubscription.daily_files_limit > 0
        &&
        (
          (
            this.userSubscription.package_rewriting_file > 0
            ||
            this.userSubscription.monthly_rewriting_file > 0
          )
          ||
          this.userSubscription.plan_id <= 1
        )
      )
      {
        this.appService.startDispatchingGetHistoryFile(this.formattedDate, this.itemsPerPage, this.pageIndex, this.searchName);
        if(type === 'humanized')
          this.store$.dispatch(HumanizedFile({historyId}));
        else if(type === 'rewrite')
          this.store$.dispatch(RewriteFile({historyId}));
      }
      else if(this.userSubscription.plan_id <= 1)
      {
        this.store$.dispatch(PaddleCheckout({ planId: 2, period: 'monthly'}));
      }
      else
      {
        this.dialog.open(ExceedingLimitDialogComponent, {
          width: '600px',
          data: {}
        });
      }

    }


  }

}
