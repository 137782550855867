import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from "rxjs";
import { PlanService } from "../services/plan.service";
import { GetPlans, GetPlansFailed, GetPlansSuccess } from "./plan.actions";
import { PlanAnswer } from "../interface/plan.interface";

@Injectable()
export class PlanEffects {

  GetPlan$ = createEffect(() => this.actions$.pipe(
    ofType(GetPlans),
    switchMap(() => this.planService.getPlans().pipe(
      map((plan: PlanAnswer) => {
        if (Array.isArray(plan.data) &&  plan.data.length > 0)
        {
          return GetPlansSuccess({ data: plan.data });
        }
        else
        {
          return GetPlansFailed();
        }
      }),
      catchError(error => of(GetPlansFailed()))
    ))
  ));



  constructor(
    private actions$: Actions,
    private store$: Store,
    private planService: PlanService
  ){}

}
