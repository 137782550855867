import { Component, OnDestroy, ElementRef, ViewChild, OnInit, Input } from '@angular/core';
import { CustomDialogComponent } from '../../dialog/custom-dialog/custom-dialog.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, combineLatest, interval, of, switchMap, takeUntil } from 'rxjs';
import { getHistoryFileStore, getLanguages } from '../../../core/store/app-store/store/app.selectors';
import { Languages } from '../../../core/store/app-store/interface/languages.interface';
import { Router } from '@angular/router';
import { GetHistoryFile, HumanizedFileSuccess, RewriteFileSuccess, UploadFile, UploadFileSuccess } from '../../../core/store/app-store/store/app.actions';
import { Actions, ofType } from '@ngrx/effects';
import { HistoryData, HistoryFile } from '../../../core/store/app-store/interface/historyfile.interface';
import { format } from 'date-fns';
import { PageEvent } from '@angular/material/paginator';
import { latinLettersValidator } from '../../../core/validator/latin-letters.validator';
import { SubscriptionUser } from '../../../core/store/subscription-store/interface/subscription.interface';
import { getSubscriptionUser } from '../../../core/store/subscription-store/store/subscription.selectors';

interface FileItem {
  name: string;
  date: string;
}

@Component({
  selector: 'app-file-rewrite',
  templateUrl: './file-rewrite.component.html',
  styleUrl: './file-rewrite.component.sass'
})

export class FileRewriteComponent implements OnInit, OnDestroy {

  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  form: FormGroup;
  languages$: Observable<Languages[]> = this.store$.pipe(select(getLanguages))
  languages: Languages[] = [];

  isDraggingOver: boolean = false;
  uploadedFileName: string | null = null;
  maxFileSize = 10 * 1024 * 1024;
  fileSizesPlan = 10;

  validFileExtensions = ['.docx', '.pdf', '.txt'];

  itemsPerPageSelect: number[] = [10, 20, 40, 60, 100];
  itemsPerPage = 10;

  formattedDate = '';
  searchName = '';

  isUploading: boolean = false;

  private destroy$ = new Subject<void>();

  getHistoryFile$: Observable<HistoryData> = this.store$.pipe(select(getHistoryFileStore));
  historys: HistoryFile[] = [];
  totalItems: number = 0;
  pageIndex: number = 0;

  getSubscriptionUser$: Observable<SubscriptionUser> = this.store$.pipe(select(getSubscriptionUser))


  constructor(
    private fb: FormBuilder,
    private store$: Store,
    private dialog: MatDialog,
    private router: Router,
    private actions$: Actions
  )
  {

    this.getSubscriptionUser$.subscribe( (res: SubscriptionUser) => {
      this.fileSizesPlan = res.file_sizes ?? 10;
      this.maxFileSize = this.fileSizesPlan * 1024 * 1024;
    })

    this.form = this.fb.group({
      file: [null, Validators.required],
      language: [{ value: '', disabled: true }, Validators.required]
    });

    combineLatest([this.languages$, this.getHistoryFile$]).subscribe(([languages, historyData]) => {

      const languagesMap = languages.reduce((acc: any, lang) => {
        acc[lang.id_languages] = lang.full_name;
        return acc;
      }, {});

      const historyWithLanguages = historyData.history.map(hisItem => ({
        ...hisItem,
        name_language: languagesMap[hisItem.id_languages] || ''
      }));

      this.historys = historyWithLanguages;
      this.totalItems = historyData.count;
      this.pageIndex = historyData.page - 1;
    });

  }

  get acceptedFileTypes(): string {
    return this.validFileExtensions.join(',');
  }


  ngOnInit() {

    this.actions$.pipe(
      ofType(UploadFileSuccess),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.isUploading = false;
      this.resetForm()
      this.store$.dispatch(GetHistoryFile({
        limit: this.itemsPerPage
      }));
    });

  }



  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  resetForm() {
    this.form.reset({
      file: null,
      language: { value: '', disabled: true }
    });
    this.uploadedFileName = null;
  }

  onItemsPerPageChange(value: string) {
    this.itemsPerPage = parseInt(value, 10);
  }

  onPageChange(event: PageEvent) {
    this.itemsPerPage = event.pageSize;
    this.loadData(event.pageIndex, this.itemsPerPage);
  }

  loadData(pageIndex: number, pageSize: number) {

    let req: {limit: number, page: number, name?: string, date?: string } = {
      limit: pageSize,
      page: pageIndex + 1
    }

    if(this.formattedDate.length > 0 )
    {
      req = {
        ...req,
        date: this.formattedDate
      }
    }

    if(this.searchName.length > 0 )
    {
      req = {
        ...req,
        name: this.searchName
      }
    }

    this.store$.dispatch(GetHistoryFile(req));
  }

  applyFilter(event: Event) {

    const input = event.target as HTMLInputElement;
    this.searchName = input.value;

    if( this.searchName.length > 0 )
    {
      let req: {limit: number, name: string, date?: string } = {
        limit: this.itemsPerPage,
        name: this.searchName
      }

      if(this.formattedDate.length > 0 )
      {
        req = {
          ...req,
          date: this.formattedDate
        }
      }

      this.store$.dispatch(GetHistoryFile(req));
    }


  }

  applyDateFilter(date: Date) {

    this.formattedDate = format(date, 'yyyy-MM-dd');

    if(this.formattedDate.length > 0)
    {
      let req: {limit: number, date: string, name?: string } = {
        limit: this.itemsPerPage,
        date: this.formattedDate
      }

      if(this.searchName.length > 0 )
      {
        req = {
          ...req,
          name: this.searchName
        }
      }

      this.store$.dispatch(GetHistoryFile(req));
    }


  }

  isValidFileFormat(fileName: string): boolean {
    return this.validFileExtensions.some(ext => fileName.toLowerCase().endsWith(ext));
  }

  isValidFileSize(fileSize: number): boolean {
    return fileSize <= this.maxFileSize;
  }

  openFileSelector() {
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: any) {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length) {
      const file = input.files[0];

      if (this.isValidFileFormat(file.name) && this.isValidFileSize(file.size)) {
        this.uploadedFileName = file.name;
        this.form.patchValue({ file: file });
        this.form.get('language')?.enable();
      } else {
        this.dialog.open(CustomDialogComponent, {
          disableClose: true
        });
      }
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDraggingOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.isDraggingOver = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.isDraggingOver = false;

    if (event.dataTransfer && event.dataTransfer.files.length) {
      const file = event.dataTransfer.files[0];
      if (this.isValidFileFormat(file.name) && this.isValidFileSize(file.size)) {
        this.uploadedFileName = file.name;
        this.form.patchValue({ file: file });
        this.form.get('language')?.enable();
      } else {
        this.dialog.open(CustomDialogComponent, {
          disableClose: true
        });
      }
    }
  }

  removeFile() {
    this.uploadedFileName = null;
    this.form.patchValue({ file: null });
    this.form.get('language')?.disable();
  }

  onSubmit() {
    if (this.form.valid) {
      this.isUploading = true;
      const formData: FormData = new FormData();
      const fileToUpload = this.form.get('file')?.value;
      const languageId = this.form.get('language')?.value;
      formData.append('file', fileToUpload, fileToUpload.name);
      formData.append('languageId', languageId);

      this.store$.dispatch(UploadFile({data: formData}));
    }
  }

}
