import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthGoogle, Authorization, AuthorizationSuccess } from '../../../core/store/auth-store/store/auth.actions';
import { Store, select } from '@ngrx/store';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { passwordMatchValidator } from '../../../core/validator/password-match.validator';
import { Observable, Subject, debounceTime, distinctUntilChanged, filter, take, takeUntil } from 'rxjs';
import { getAuthStatus } from '../../../core/store/auth-store/store/auth.selectors';
import { FindUser, Registration, RegistrationReset } from '../../../core/store/reg-store/store/reg.actions';
import { getExistsEmailStatus, getExistsLoginStatus, getRegStatus } from '../../../core/store/reg-store/store/reg.selectors';
import { UniqueUserValidator } from '../../../core/validator/unique-user.validator';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../dialog/confirmation-dialog/confirmation-dialog.component';
import { ErrorRegDialogComponent } from '../../dialog/error-reg-dialog/error-reg-dialog.component';
import { PromoFile } from '../../../core/store/app-store/interface/receivingfile.interface';
import { getPromoFile } from '../../../core/store/app-store/store/app.selectors';
import { Actions, ofType } from '@ngrx/effects';
import { AddPromoFile, AddPromoFileSuccess, ReceivingFileSuccess, ResetPromoFile } from '../../../core/store/app-store/store/app.actions';

import { jwtDecode } from 'jwt-decode';

declare const google: any;

@Component({
  selector: 'app-authorization',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.sass']
})
export class AuthorizationComponent implements OnInit {

  showRegisterEmail: boolean = false;

  formAuth: FormGroup;

  getExistsLoginStatus$: Observable<boolean> = this.store$.pipe(select(getExistsLoginStatus))

  getExistsEmailStatus$: Observable<boolean> = this.store$.pipe(select(getExistsEmailStatus))

  getRegStatus$: Observable<any> = this.store$.pipe(select(getRegStatus))

  promoFile$: Observable<PromoFile> = this.store$.pipe(select(getPromoFile));
  promoFile!: PromoFile;

  private destroy$ = new Subject<void>();

  queryPromoFile: any;
  hide = true;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store$: Store,
    private formBuilder: FormBuilder,
    private uniqueUserValidator: UniqueUserValidator,
    private dialog: MatDialog,
    private actions$: Actions,
    private ngZone: NgZone
  )
  {


    this.store$.pipe(
      select(getAuthStatus),
      take(1)
    ).subscribe(getAuthStatus => {
      if (getAuthStatus) {
        this.router.navigate(['/aiworker/statistics']);
      }
    });


    this.formAuth = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });


    this.actions$.pipe(
      ofType(AuthorizationSuccess),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      if(this.promoFile.status)
      {
        const { status, ...promoFile }: any = this.promoFile;
        this.store$.dispatch(AddPromoFile(promoFile));
      }
    });

    this.actions$.pipe(
      ofType(AddPromoFileSuccess),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.store$.dispatch(ResetPromoFile());
    });

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {

    this.loadGoogleApi();

    this.route.queryParams.subscribe(params => {

      if(Object.keys(params).length > 0)
      {
        const encodedPromoFile = params['promoFile'];

        const decodedPromoFile = JSON.parse(atob(encodedPromoFile));

        const { languageId, originalName, newName, fileType } = decodedPromoFile;

        this.store$.dispatch(ReceivingFileSuccess({
          status: true,
          languageId,
          originalName,
          newName,
          fileType
        }));
      }

    });


  }

  ngAfterViewInit() {
    if (typeof google !== 'undefined') {
      this.initializeGoogleSignIn();
    } else {
      window.addEventListener('google-loaded', this.initializeGoogleSignIn.bind(this));
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.onload = () => window.dispatchEvent(new Event('google-loaded'));
      document.body.appendChild(script);
    }
  }

  loadGoogleApi() {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = () => this.initializeGoogleSignIn();
    document.body.appendChild(script);
  }

  initializeGoogleSignIn() {
    google.accounts.id.initialize({
      client_id: '1078394747503-sjmedrtjgbv9cdogsmfb1cdpr802ft1t.apps.googleusercontent.com',
      callback: this.onSignInWithGoogle.bind(this)
    });
    google.accounts.id.renderButton(
      document.getElementById('googleBtn'),
      { theme: 'outline', size: 'large' }
    );
  }

  onLogUp(): void {

    this.router.navigate(['/register']);

  }

  onLogIn(): void {

    if (this.formAuth.valid) {
      this.store$.dispatch(Authorization(this.formAuth.value));
    }

  }


  onShowRegisterEmail(): void {
    this.showRegisterEmail = !this.showRegisterEmail;
  }


  onSignInWithGoogle(response: any): void {
    const auth = this.decodeJWT(response.credential);
    if(auth)
    {
      this.ngZone.run(() => {
        this.store$.dispatch(AuthGoogle(auth));
      });
    }
  }

  onSignInWithApple(): void {

  }

  decodeJWT(token: string): any {
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error('Failed to decode JWT:', error);
      return null;
    }
  }


}
