import { createReducer, on } from '@ngrx/store';
import { AuthState } from '../interface/auth.interface';
import { AuthGoogleSuccess, AuthorizationSuccess, AutoAuthorization, ResetAuth } from './auth.actions';

export const AUTH_FEATURENAME = "authorization";

const authState: Partial<AuthState> = {
  token: "",
  status: false
};

export const authReducer = createReducer(
  authState,
  on(AuthorizationSuccess, AuthGoogleSuccess, (state, auth) => ({
    ...state,
    ...auth
  })),
  on(AutoAuthorization, (state, auth) => ({
    ...state,
    ...auth
  })),
  on(ResetAuth, (state) => ({
    ...state,
    ...authState
  }))
);
