import {  createReducer, on } from '@ngrx/store';
import { PlanState } from '../interface/plan.interface';
import { GetPlansFailed, GetPlansSuccess, IsYearly, ResetPlan } from './plan.actions';

export const PLAN_FEATURENAME = "plan";

const planState: Partial<PlanState> = {
  isYearly: false,
  plans: []
};

export const planReducer = createReducer(
  planState,

  on(GetPlansSuccess, (state, {data}) => ({
    ...state,
    isYearly: false,
    plans: data
  })),

  on(GetPlansFailed, (state) => ({
    ...state,
    isYearly: false,
    plans: []
  })),

  on(IsYearly, (state, {active}) => ({
    ...state,
    isYearly: (active === true || active === false) ? active : !state.isYearly
  })),

  on(ResetPlan, (state) => ({
    ...state,
    ...planState
  }))

);
