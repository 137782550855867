<mat-sidenav-container class="sidenav-container">
  <mat-sidenav mode="side" *ngIf="!isHandset" opened class="sidenav">
    <!-- Sidenav content -->
    <div class="sidenav-header">
      <span class="toolbar-title">
        <img src="assets/icon_white.webp" alt="NewTXT" />
      </span>
    </div>

    <mat-nav-list>
      <!-- Sidenav links -->
      <a
        mat-list-item
        routerLink="/aiworker/statistics"
        [class.active-link]="currentUrl === 'Statistics'"
        (click)="updateMenuTitle('Statistics')"
      >
        <i class="fas fa-tachometer-alt"></i>
        <span class="nav-text">Statistics</span>
      </a>
      <a
        mat-list-item
        routerLink="/aiworker/file-rewrite"
        [class.active-link]="currentUrl === 'File Rewrite'"
        (click)="updateMenuTitle('File Rewriter')"
      >
        <i class="fas fa-tools"></i>
        <span class="nav-text">File Rewriter</span>
      </a>
      <a
        mat-list-item
        routerLink="/aiworker/subscription"
        [class.active-link]="currentUrl === 'Subscription'"
        (click)="updateMenuTitle('Subscription')"
      >
        <i class="fas fa-file-invoice-dollar"></i>
        <span class="nav-text">Subscription</span>
      </a>
      <a
        mat-list-item
        routerLink="/aiworker/support"
        [class.active-link]="currentUrl === 'Support'"
        (click)="updateMenuTitle('Support')"
      >
        <i class="fas fa-headset"></i>
        <span class="nav-text">Support</span>
      </a>
    </mat-nav-list>

    <app-footer-company></app-footer-company>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar id="bg-off">
      <div>
        <button
          mat-icon-button
          routerLink="/aiworker/settings"
          [class.active-link]="currentUrl === '/aiworker/settings'"
          (click)="updateMenuTitle('Settings')"
        >
          <i class="fas fa-user-circle toolbar-icon"></i>
        </button>
        <button
          mat-icon-button
          routerLink="/aiworker/billing"
          [class.active-link]="currentUrl === '/aiworker/billing'"
          (click)="updateMenuTitle('Billing')"
        >
          <i class="fas fa-file-invoice-dollar toolbar-icon"></i>
        </button>
      </div>

      <span class="toolbar-spacer">
        <h2>{{ currentUrl }}</h2>
      </span>

      <div class="toolbar-right">
        <span class="header-email">{{ userData.email }}</span>
        <button mat-icon-button (click)="onLogOut()">
          <i class="fas fa-sign-out-alt toolbar-icon"></i>
        </button>
      </div>
    </mat-toolbar>

    <div id="work-space">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<mat-dialog-content class="expon-file-rewrite"></mat-dialog-content>

<mat-tab-group
  *ngIf="isHandset"
  class="mobile-tab-bar"
  mat-stretch-tabs
  (selectedTabChange)="onTabChange($event)"
  [selectedIndex]="currentTabIndex"
  color="primary"
>
  <mat-tab>
    <ng-template mat-tab-label>
      <div class="tab-content">
        <i class="fas fa-tachometer-alt"></i>
        <span>Statistics</span>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <div class="tab-content">
        <i class="fas fa-tools"></i>
        <span>File Rewrite</span>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <div class="tab-content">
        <i class="fas fa-file-invoice-dollar"></i>
        <span>Billing</span>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <div class="tab-content">
        <i class="fas fa-headset"></i>
        <span>Support</span>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>
