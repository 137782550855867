import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthorizationComponent } from './components/login/authorization/authorization.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { authGuard } from './auth.guard';
import { BillingComponent } from './components/billing/billing.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { SettingsComponent } from './components/settings/settings.component';
import { FileRewriteComponent } from './components/tools/file-rewrite/file-rewrite.component';
import { MenuComponent } from './components/menu/menu.component';
import { MailConfirmationComponent } from './components/mail-confirmation/mail-confirmation.component';
import { SupportComponent } from './components/support/support.component';
import { RegistrationComponent } from './components/login/registration/registration.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AccountRecoveryComponent } from './components/login/account-recovery/account-recovery.component';


const routes: Routes = [

  { path: 'login', component: AuthorizationComponent },
  { path: 'register', component: RegistrationComponent },
  { path: 'account-recovery', component: AccountRecoveryComponent },
  { path: 'confirmation', component: MailConfirmationComponent},
  { path: '', redirectTo: '/aiworker', pathMatch: 'full' },
  {
    path: 'aiworker',
    component: MenuComponent,
    children: [
      { path: 'statistics', component: StatisticsComponent, canActivate: [authGuard] },
      { path: 'billing', component: BillingComponent, canActivate: [authGuard] },
      { path: 'settings', component: SettingsComponent, canActivate: [authGuard] },
      { path: 'subscription', component: SubscriptionComponent, canActivate: [authGuard] },
      { path: 'support', component: SupportComponent, canActivate: [authGuard] },
      { path: 'file-rewrite', component: FileRewriteComponent, canActivate: [authGuard] }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
