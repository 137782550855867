import { createAction, props } from "@ngrx/store";
import { Languages } from "../interface/languages.interface";
import { PromoFile } from "../interface/receivingfile.interface";
import { DeleteHistoryRequest, HistoryData, HistoryFileRequest } from "../interface/historyfile.interface";
import { RewriteFileRequest } from "../interface/rewritefile.interface";
import { DownloadFileRequest } from "../interface/downloadfile.interface";
import { AddPromoFileRequest } from "../interface/addpromofile.interface";
import { HumanizedFileRequest } from "../interface/humanizedfile.interface";
import { SupportMessageRequest } from "../interface/support.interface";

export const GetLanguages = createAction(
  '[GetLanguages]'
)

export const GetLanguagesSuccess = createAction(
  '[GetLanguages] success',
  props<{data: Languages[]}>()
)

export const GetLanguagesFailed = createAction(
  '[GetLanguages] failed'
)

export const ReceivingFile = createAction(
  '[ReceivingFile]',
  props<{ promoFile: FormData, languageId: number }>()
)

export const ReceivingFileSuccess = createAction(
  '[ReceivingFile] success',
  props<PromoFile>()
)

export const ReceivingFileFailed = createAction(
  '[GetLanguages] failed'
)

export const UploadFile = createAction(
  '[UploadFile]',
  props<{ data: FormData }>()
)

export const UploadFileSuccess = createAction(
  '[UploadFile] success'
)

export const UploadFileFailed = createAction(
  '[UploadFile] failed'
)

export const GetHistoryFile = createAction(
  '[GetHistoryFile]',
  props<HistoryFileRequest>()
)

export const GetHistoryFileSuccess = createAction(
  '[GetHistoryFile] success',
  props<HistoryData>()
)

export const GetHistoryFileFailed = createAction(
  '[GetHistoryFile] failed'
)

export const DeleteHistory = createAction(
  '[DeleteHistory]',
  props<DeleteHistoryRequest>()
)

export const DeleteHistorySuccess = createAction(
  '[DeleteHistory] success'
)

export const DeleteHistoryFailed = createAction(
  '[DeleteHistory] failed'
)

export const RewriteFile = createAction(
  '[RewriteFile]',
  props<RewriteFileRequest>()
)

export const RewriteFileSuccess = createAction(
  '[RewriteFile] success'
)

export const RewriteFileFailed = createAction(
  '[RewriteFile] failed'
)

export const HumanizedFile = createAction(
  '[HumanizedFile]',
  props<HumanizedFileRequest>()
)

export const HumanizedFileSuccess = createAction(
  '[HumanizedFile] success'
)

export const HumanizedFileFailed = createAction(
  '[HumanizedFile] failed'
)

export const DownloadFile = createAction(
  '[DownloadFile]',
  props<DownloadFileRequest>()
)

export const DownloadFileSuccess = createAction(
  '[DownloadFile] success'
)

export const DownloadFileFailed = createAction(
  '[DownloadFile] failed'
)

export const AddPromoFile = createAction(
  '[AddPromoFile]',
  props<AddPromoFileRequest>()
)

export const AddPromoFileSuccess = createAction(
  '[AddPromoFile] success'
)

export const AddPromoFileFailed = createAction(
  '[AddPromoFile] failed'
)

export const ResetPromoFile = createAction(
  '[AddPromoFile] reset'
)

export const SupportMessage = createAction(
  '[SupportMessage]',
  props<SupportMessageRequest>()
)

export const SupportMessageSuccess = createAction(
  '[SupportMessage] success'
)

export const SupportMessageFailed = createAction(
  '[SupportMessage] failed'
)

export const ResetApp = createAction(
  '[ResetApp]'
)
