import { createReducer, on } from '@ngrx/store';
import { RegState } from '../interface/reg.interface';
import { FindUserFailed, FindUserSuccess, RegistrationFailed, RegistrationGoogleFailed, RegistrationGoogleSuccess, RegistrationGoogleWarning, RegistrationReset, RegistrationSuccess, RegistrationWarning, ResetReg } from './reg.actions';

export const REG_FEATURENAME = "registration";

const regState: Partial<RegState> = {
  status: null,
  existsLogin: false,
  existsEmail: false
};

export const regReducer = createReducer(
  regState,

  on(RegistrationSuccess, RegistrationGoogleSuccess, (state) => ({
    ...state,
    status: "success"
  })),

  on(RegistrationFailed, RegistrationGoogleFailed, (state) => ({
    ...state,
    status: "failed"
  })),

  on(RegistrationWarning, RegistrationGoogleWarning, (state) => ({
    ...state,
    status: "warning"
  })),

  on(RegistrationReset, (state) => ({
    ...state,
    status: null,
    existsLogin: false,
    existsEmail: false
  })),

  on(FindUserSuccess, (state, {existsType}) => ({
    ...state,
    ...(existsType === "login") ? {existsLogin: true} : {existsEmail: true}
  })),

  on(FindUserFailed, (state) => ({
    ...state,
    existsLogin: false,
    existsEmail: false
  })),

  on(ResetReg, (state) => ({
    ...state,
    ...regState
  }))

);
