<div class="settings-page">
  <div class="settings-column">
    <!-- First Column -->
    <div class="column">
      <!-- Block 1: Profile and Names -->
      <mat-card>
        <mat-card-content>

          <form [formGroup]="formIco" (ngSubmit)="onSubmitIco()">

            <div class="profile-picture-upload">
              <div class="profile-picture-preview">
                <img
                  *ngIf="profilePicture; else defaultPicture"
                  [src]="profilePicture"
                  alt="Profile Picture"
                />
                <ng-template #defaultPicture>
                  <i class="fas fa-user-circle default-user-icon"></i>
                </ng-template>
              </div>
              <label for="profile-picture">
                <button mat-raised-button color="primary" class="upload-button" type="submit">
                  <i class="fas fa-upload"></i> Upload
                </button>
              </label>
              <input
                id="profile-picture"
                type="file"
                hidden
                (change)="onProfilePictureChange($event)"
              />
            </div>

          </form>


          <!-- First Name -->
          <mat-form-field class="full-width">
            <input matInput placeholder="First Name" />
          </mat-form-field>
          <!-- Last Name -->
          <mat-form-field class="full-width">
            <input matInput placeholder="Last Name" />
          </mat-form-field>
          <button mat-raised-button color="primary">
            <i class="fa-solid fa-floppy-disk"></i> Save Changes
          </button>
        </mat-card-content>
      </mat-card>

      <!-- Block 2: Word Limit Progress -->
      <mat-card>
        <mat-card-header>
          <h2 class="card-title-large">Available Words Limit</h2>
        </mat-card-header>
        <mat-card-content>
          <div class="custom-progress-bar">
            <div class="progress-fill" [style.width.%]="50"></div>
          </div>
          <div class="word-info">
            <span>200 words spent</span>
            <span>2000 words left</span>
          </div>
        </mat-card-content>
      </mat-card>

      <!-- Block 3: Notification Settings -->
      <mat-card>
        <mat-card-header>
          <h2 class="card-title-large">Email Notifications</h2>
        </mat-card-header>
        <mat-card-content>
          <mat-checkbox>Email Notifications</mat-checkbox>
          <br />
          <mat-checkbox>Send Rewriting History</mat-checkbox>
        </mat-card-content>
      </mat-card>
    </div>

    <!-- Second Column -->
    <div class="column">
      <!-- Single Block: Security Settings -->
      <mat-card>
        <h2 class="card-title-large">Account Security</h2>
        <mat-card-header>
          <h3 class="card-title-medium">Change Login</h3>
        </mat-card-header>
        <mat-card-content>
          <mat-form-field class="full-width">
            <input matInput placeholder="New Login" />
          </mat-form-field>
          <button mat-raised-button color="primary">
            <i class="fas fa-user-edit"></i> Change Login
          </button>
        </mat-card-content>
        <mat-card-header>
          <h3 class="card-title-medium">Change Email</h3>
        </mat-card-header>
        <mat-card-content>
          <mat-form-field class="full-width">
            <input matInput type="email" placeholder="New Email" />
          </mat-form-field>
          <mat-form-field class="full-width">
            <input matInput type="email" placeholder="Confirm Email" />
          </mat-form-field>
          <button mat-raised-button color="primary">
            <i class="fas fa-envelope-open"></i> Change Email
          </button>
        </mat-card-content>

        <mat-card-header>
          <h3 class="card-title-medium">Change Password</h3>
        </mat-card-header>
        <mat-card-content>
          <mat-form-field class="full-width">
            <input matInput type="password" placeholder="New Password" />
          </mat-form-field>
          <mat-form-field class="full-width">
            <input matInput type="password" placeholder="Confirm Password" />
          </mat-form-field>
          <button mat-raised-button color="primary">
            <i class="fas fa-key"></i> Change Password
          </button>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
