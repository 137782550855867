import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-setting-dialog',
  templateUrl: './setting-dialog.component.html',
  styleUrl: './setting-dialog.component.sass'
})
export class SettingDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public message: string) {}

}
