/**
 * Обработка запросов
 */

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { first, catchError, mergeMap } from 'rxjs/operators';
import { getToken } from '../auth-store/store/auth.selectors';
import { ResetApp } from '../app-store/store/app.actions';
import { ResetAuth } from '../auth-store/store/auth.actions';
import { ResetPaddle } from '../paddle-store/store/paddle.actions';
import { ResetPlan } from '../plan-store/store/plan.actions';
import { ResetReg } from '../reg-store/store/reg.actions';
import { ResetSubscription } from '../subscription-store/store/subscription.actions';
import { ResetUser } from '../user-store/store/user.actions';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(
    private store$: Store
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {

    return this.store$.pipe(
      select(getToken),
      first(),
      mergeMap(token => {

        const authRequest = !!token ? request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        }) : request;

        return next.handle(authRequest).pipe(

          catchError(err => {

            if (err instanceof HttpErrorResponse) {

              if (err.status === 401) {
                console.error("Redirect on login page OR sign out");
                this.logout();
                return EMPTY;
              }
            }

            throw err;
          })
        );

      })
    );
  }

  logout(): void {
    this.store$.dispatch(ResetApp());
    this.store$.dispatch(ResetAuth());
    this.store$.dispatch(ResetPaddle());
    this.store$.dispatch(ResetPlan());
    this.store$.dispatch(ResetReg());
    this.store$.dispatch(ResetSubscription());
    this.store$.dispatch(ResetUser());
  }

}
