// auth.effects.ts

import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AccountRecovery, AccountRecoveryFailed, AccountRecoverySuccess, FindUser, FindUserFailed, FindUserSuccess, GetCode, GetCodeFailed, GetCodeSuccess, Registration, RegistrationFailed, RegistrationGoogle, RegistrationGoogleFailed, RegistrationGoogleSuccess, RegistrationGoogleWarning, RegistrationSuccess, RegistrationWarning, SaveStatusClient, VerificationCode, VerificationCodeFailed, VerificationCodeSuccess } from './reg.actions';
import { map, switchMap, catchError, tap, first, filter } from 'rxjs/operators';
import { of } from "rxjs";
import { BrowserService } from "../../../services/browser/browser.service";
import { RegService } from "../services/reg.service";
import { AccountRecoveryAnswer, AccountRecoveryRequest, FindUserRequest, GetCodeAnswer, GetCodeRequest, RegAnswer, RegRequest, VerificationCodeAnswer, VerificationCodeRequest } from "../interface/reg.interface";
import { Store } from "@ngrx/store";

@Injectable()
export class RegEffects {

  SaveInfoByUser$ = createEffect(() => this.actions$.pipe(
    ofType(RegistrationSuccess, RegistrationGoogleSuccess),
    tap(() => {
      this.browserService.saveInfoByUser();
    })
  ), { dispatch: false });

  Registration$ = createEffect(() => this.actions$.pipe(

    ofType(Registration),
    switchMap(reg => this.regService.registrationUser(reg)
      .pipe(
        map((reg: RegAnswer) => {

          if (reg.status === "success")
          {
            this.store$.dispatch(SaveStatusClient(reg.data))
            return RegistrationSuccess();
          }
          else if ('error' in reg)
          {
            return RegistrationFailed();
          }
          else
          {
            return RegistrationWarning();
          }
        }),
        catchError(error => of(RegistrationFailed()))
      )
    )

  ));

  RegistrationGoogle$ = createEffect(() => this.actions$.pipe(

    ofType(RegistrationGoogle),
    switchMap(reg => this.regService.registrationUserGoogle(reg)
      .pipe(
        map((reg: RegAnswer) => {

          if (reg.status === "success")
          {
            this.store$.dispatch(SaveStatusClient(reg.data))
            return RegistrationGoogleSuccess();
          }
          else if('error' in reg)
          {
            return RegistrationGoogleFailed();
          }
          else {
            return RegistrationGoogleWarning()
          }
        }),
        catchError(error => of(RegistrationGoogleFailed()))
      )
    )

  ));

  SaveStatusClient$ = createEffect(() => this.actions$.pipe(
    ofType(SaveStatusClient),
    tap((data) => {
      this.browserService.saveStatusClient(data.statusCient);
    })
  ), { dispatch: false })

  FindUser$ = createEffect(() => this.actions$.pipe(

    ofType(FindUser),
    switchMap((findUserData: FindUserRequest) => this.regService.findUser(findUserData)
      .pipe(
        map((findUser: boolean) => {
          if (findUser)
          {
            const existsType: string = (Object.keys(findUserData).indexOf("login") !== -1) ? "login" : "email";
            return FindUserSuccess({existsType});
          }
          else
          {
            return FindUserFailed();
          }
        }),
        catchError(error => of(FindUserFailed()))
      )
    )

  ));

  AccountRecovery$ = createEffect(() => this.actions$.pipe(
    ofType(AccountRecovery),
    switchMap((userData: AccountRecoveryRequest) => this.regService.accountRecovery(userData)
      .pipe(
        map((data: AccountRecoveryAnswer) => {
          if (data.status === "success")
          {
            return AccountRecoverySuccess();
          }
          else
          {
            return AccountRecoveryFailed();
          }
        }),
        catchError(error => of(AccountRecoveryFailed()))
      ))
  ))

  GetCode$ = createEffect(() => this.actions$.pipe(
    ofType(GetCode),
    switchMap((userData: GetCodeRequest) => this.regService.getCode(userData)
      .pipe(
        map((answer: GetCodeAnswer) => {
          if (answer.status === "success")
          {
            return GetCodeSuccess({email: answer.data.email});
          }
          else
          {
            return GetCodeFailed();
          }
        }),
        catchError(error => of(GetCodeFailed()))
      ))
  ))

  VerificationCode$ = createEffect(() => this.actions$.pipe(
    ofType(VerificationCode),
    switchMap((userData: VerificationCodeRequest) => this.regService.verificationCode(userData)
      .pipe(
        map((answer: VerificationCodeAnswer) => {
          if (answer.status === "success")
          {
            return VerificationCodeSuccess();
          }
          else
          {
            return VerificationCodeFailed();
          }
        }),
        catchError(error => of(VerificationCodeFailed()))
      ))
  ))

  constructor(
    private actions$: Actions,
    private browserService: BrowserService,
    private regService: RegService,
    private store$: Store
  ){}

}
