import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-company',
  templateUrl: './footer-company.component.html',
  styleUrl: './footer-company.component.sass'
})
export class FooterCompanyComponent {

}
