import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { PaddleCheckout } from '../../../core/store/paddle-store/store/paddle.actions';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-exceeding-limit-dialog',
  templateUrl: './exceeding-limit-dialog.component.html',
  styleUrl: './exceeding-limit-dialog.component.sass'
})
export class ExceedingLimitDialogComponent {

  constructor(
    private store$: Store,
    public dialogRef: MatDialogRef<ExceedingLimitDialogComponent>
  ){}

  onBuyPlan(): void {
    this.store$.dispatch(PaddleCheckout({ planId: 2, period: "monthly" }));
    this.dialogRef.close();
  }

}
