<h1 mat-dialog-title><i class="fas fa-surprise"></i> Amazing!</h1>
<div mat-dialog-content>
  <p>
    Wow! It looks like you've already tried all the advantages of our system! To use your remaining limit, you need to wait for the end of the trial period or buy a subscription right now.
  </p>
</div>
<div mat-dialog-actions align="center" class="action-buttons-dialog">
  <button mat-button mat-dialog-close>
    <i class="far fa-clock"></i> I'll Wait
  </button>
  <button
    mat-button
    style="border: 2px solid; border-color: #04d1ff; color: #04d1ff;"
    (click)="onBuyPlan()"
  >
    <i class="fas fa-rocket"></i> I Want to Continue
  </button>
</div>
