import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { REG_FEATURENAME, regReducer } from './store/reg.reducer';
import { metaReducersWithLocalStorage } from '../local-storage/localstorage.reducer';
import { EffectsModule } from '@ngrx/effects';
import { RegEffects } from './store/reg.effects';
import { RequestInterceptor } from '../interceptors/request.interceptor';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(
      REG_FEATURENAME,
      regReducer,
      { metaReducers: metaReducersWithLocalStorage }
    ),
    EffectsModule.forFeature([RegEffects])
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    }
  ]
})
export class RegStoreModule { }
