import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { of } from "rxjs";
import { AddPromoFile, AddPromoFileFailed, AddPromoFileSuccess, DeleteHistory, DeleteHistoryFailed, DeleteHistorySuccess, DownloadFile, DownloadFileFailed, DownloadFileSuccess, GetHistoryFile, GetHistoryFileFailed, GetHistoryFileSuccess, GetLanguages, GetLanguagesFailed, GetLanguagesSuccess, HumanizedFile, HumanizedFileFailed, HumanizedFileSuccess, ReceivingFile, ReceivingFileFailed, ReceivingFileSuccess, RewriteFile, RewriteFileFailed, RewriteFileSuccess, SupportMessage, SupportMessageFailed, SupportMessageSuccess, UploadFile, UploadFileFailed, UploadFileSuccess } from "./app.actions";
import { AppService } from "../services/app.service";
import { PromoFile } from "../interface/receivingfile.interface";
import { GetLanguagesAnswer } from "../interface/languages.interface";
import { DeleteHistoryRequest, HistoryFileRequest } from "../interface/historyfile.interface";
import { RewriteFileRequest } from "../interface/rewritefile.interface";
import { DownloadFileRequest } from "../interface/downloadfile.interface";
import { AddPromoFileRequest } from "../interface/addpromofile.interface";
import { HumanizedFileRequest } from "../interface/humanizedfile.interface";
import { SupportMessageRequest } from "../interface/support.interface";
import { Router } from "@angular/router";
import { GetSubscription } from "../../subscription-store/store/subscription.actions";

@Injectable()
export class AppEffects {

  GetLanguages$ = createEffect(() => this.actions$.pipe(
    ofType(GetLanguages),
    switchMap(() => this.appService.getLanguages().pipe(
      map((languages: GetLanguagesAnswer) => {
        return GetLanguagesSuccess({data: languages.data});
      }),
      catchError(error => of(GetLanguagesFailed()))
    ))
  ))

  ReceivingFile$ = createEffect(() => this.actions$.pipe(
    ofType(ReceivingFile),
    switchMap((action: { promoFile: FormData, languageId: number}) => this.appService.receivingFile(action.promoFile).pipe(
      map((promoFile: PromoFile) => {
        promoFile.languageId = action.languageId;
        return ReceivingFileSuccess(promoFile);
      }),
      catchError(error => of(ReceivingFileFailed()))
    ))
  ))

  UploadFile$ = createEffect(() => this.actions$.pipe(
    ofType(UploadFile),
    switchMap((action: { data: FormData }) => this.appService.uploadFile(action.data).pipe(
      map((data) => {

        if(data.status == "success")
          return UploadFileSuccess();
        else
          return UploadFileFailed();

      }),
      catchError(error => of(UploadFileFailed()))
    ))
  ))

  GetHistoryFile$ = createEffect(() => this.actions$.pipe(
    ofType(GetHistoryFile),
    switchMap((data: HistoryFileRequest) => this.appService.getHistoryFile(data).pipe(
      map((history) => {

        if(history.status == "success")
        {
          this.store$.dispatch(GetSubscription());
          return GetHistoryFileSuccess(history.data);
        }
        else
          return GetHistoryFileFailed();

      }),
      catchError(error => of(GetHistoryFileFailed()))
    ))
  ))

  DeleteHistory$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteHistory),
    switchMap((data: DeleteHistoryRequest) => this.appService.deleteHistory(data).pipe(
      map(() => {

        this.store$.dispatch(GetHistoryFile({limit: 10}));
        return DeleteHistorySuccess()

      }),
      catchError(error => of(DeleteHistoryFailed()))
    ))
  ))

  RewriteFile$ = createEffect(() => this.actions$.pipe(
    ofType(RewriteFile),
    switchMap((data: RewriteFileRequest) => this.appService.rewriteFile(data).pipe(
      map(() => {

        return RewriteFileSuccess()

      }),
      catchError(error => of(RewriteFileFailed()))
    ))
  ))

  HumanizedFile$ = createEffect(() => this.actions$.pipe(
    ofType(HumanizedFile),
    switchMap((data: HumanizedFileRequest) => this.appService.humanizedFile(data).pipe(
      map(() => {

        return HumanizedFileSuccess()

      }),
      catchError(error => of(HumanizedFileFailed()))
    ))
  ))

  DownloadFile$ = createEffect(() => this.actions$.pipe(
    ofType(DownloadFile),
    switchMap((data: DownloadFileRequest) => this.appService.downloadFile(data).pipe(
      map(() => {

        return DownloadFileSuccess()

      }),
      catchError(error => of(DownloadFileFailed()))
    ))
  ))

  AddPromoFile$ = createEffect(() => this.actions$.pipe(
    ofType(AddPromoFile),
    switchMap((data: AddPromoFileRequest) => this.appService.addPromoFile(data).pipe(
      map(() => AddPromoFileSuccess()),
      tap(() => this.router.navigate(['/aiworker/file-rewrite'])),
      catchError(error => of(AddPromoFileFailed()))
    ))
  ));

  AddPromoFileSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(AddPromoFileSuccess),
    tap(() => {
      this.appService.startDispatchingGetHistoryFile('', 10, 0, '');
    }),
    map(() => AddPromoFileSuccess()),
    catchError(error => of(AddPromoFileFailed()))
  ), { dispatch: false });

  SupportMessage$ = createEffect(() => this.actions$.pipe(
    ofType(SupportMessage),
    switchMap((data: SupportMessageRequest) => this.appService.sendSupportMessage(data).pipe(
      map(() => {
        return SupportMessageSuccess()
      }),
      catchError(error => of(SupportMessageFailed()))
    ))
  ))

  SupportMessageSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(SupportMessageSuccess),
    tap(() => {
      this.appService.supportConfirmMessage();
    }),
    catchError(error => of(SupportMessageFailed()))
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store$: Store,
    private appService: AppService,
    private router: Router
  ){}

}
