import { createAction, props } from "@ngrx/store";
import { PlansState } from "../interface/plan.interface";

export const GetPlans = createAction(
  '[GetPlans] get'
)

export const GetPlansSuccess = createAction(
  '[GetPlans] success',
  props<{data: PlansState[]}>()
);

export const GetPlansFailed = createAction(
  '[GetPlans] failed'
)

export const IsYearly = createAction(
  '[IsYearly]',
  props<{active?: boolean}>()
)

export const ResetPlan = createAction(
  '[ResetPlan]'
)
