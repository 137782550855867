import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { SubscriptionCancel } from '../../../core/store/subscription-store/store/subscription.actions';

@Component({
  selector: 'app-cancel-subscription-dialog',
  templateUrl: './cancel-subscription-dialog.component.html',
  styleUrl: './cancel-subscription-dialog.component.sass'
})
export class CancelSubscriptionDialogComponent {

  constructor(
    private store$: Store,
    public dialogRef: MatDialogRef<CancelSubscriptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { paymentId: number }
  ){}

  onCancelSubscription(): void {
    this.store$.dispatch(SubscriptionCancel({paymentId: this.data.paymentId}));
    this.dialogRef.close();
  }

}
