import { createReducer, on } from '@ngrx/store';
import { PaddleState } from '../interface/paddle.interface';
import { PaddleCheckoutSuccess, ResetPaddle } from './paddle.actions';

export const PADDLE_FEATURENAME = "paddle";

const paddleState: Partial<PaddleState> = {

};

export const paddleReducer = createReducer(

  paddleState,
  on(PaddleCheckoutSuccess, (state) => ({
    ...state
  })),

  on(ResetPaddle, (state) => ({
    ...state,
    ...paddleState
  }))


);
