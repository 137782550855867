import { Injectable } from '@angular/core';
import { AccountRecoveryAnswer, AccountRecoveryRequest, FindUserAnswer, FindUserRequest, GetCodeAnswer, GetCodeRequest, RegAnswer, RegRequest, VerificationCodeAnswer, VerificationCodeRequest } from '../interface/reg.interface';
import { Observable, catchError, map, mergeMap, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ACCOUNT_RECOVERY_USER, FIND_USER, GET_CODE_USER, GOOGLE_REGISTRATION_USER, REGISTRATION_USER, VERIFICATION_CODE_USER } from '../../../global/request/registration';
import { BrowserService } from '../../../services/browser/browser.service';
import { GoogleData } from '../../auth-store/interface/auth.interface';
import { ErrorAuthGoogleDialogComponent } from '../../../../components/dialog/error-auth-google-dialog/error-auth-google-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class RegService {

  constructor(
    private httpClient: HttpClient,
    private browserService: BrowserService,
    private dialog: MatDialog
  ) { }

  registrationUser(reg: RegRequest): Observable<RegAnswer> {

    const statusClient = this.browserService.getStatusClient()

    return this.httpClient.post<RegAnswer>(REGISTRATION_USER, {
      "last_name": reg.last_name,
      "first_name": reg.first_name,
      "login": reg.login,
      "password": reg.password,
      "email": reg.email,
      statusClient
    })
    .pipe(
      map( (res) =>
        {
          return res;
        }
      )
    );

  }

  registrationUserGoogle(data: GoogleData): Observable<RegAnswer> {

    const {type, ...req}: any = data;

    return this.httpClient.post<RegAnswer>(GOOGLE_REGISTRATION_USER, {
      aud: req.aud,
      sub: req.sub,
      email: req.email,
      email_verified: req.email_verified,
      family_name: req.family_name,
      given_name: req.given_name,
      name: req.name,
      picture: req.picture
    })
    .pipe(
      mergeMap((res) => {
        if ('error' in res )
        {
          return throwError(res);
        }
        else if('warning' in res)
        {

          if( res.code === 21001 )
          {

            this.dialog.open(ErrorAuthGoogleDialogComponent, {
              data: {
                message: 'Your Google account has already been created.',
                action: 'account_exists'
              }
            });

          }

        }

        return [res];

      }),
      catchError(error => {
        return throwError(error);
      })
    );

  }

  findUser(findUser: FindUserRequest): Observable<boolean> {

    const { type, ...offindUser } = findUser;

    return this.httpClient.post<FindUserAnswer>(FIND_USER, offindUser)
    .pipe(
      map( (res) =>
        {
          if(res.status === "success")
            return res.data.exists;
          else
            return false;
        }
      )
    );

  }

  accountRecovery(userData: AccountRecoveryRequest): Observable<AccountRecoveryAnswer> {

    const { type, ...req }: any  = userData;

    return this.httpClient.post<AccountRecoveryAnswer>(ACCOUNT_RECOVERY_USER, req)
    .pipe(
      map( (res) =>
        {
          return res;
        }
      )
    );

  }

  getCode(userData: GetCodeRequest): Observable<GetCodeAnswer> {

    const { type, ...req }: any  = userData;

    return this.httpClient.post<GetCodeAnswer>(GET_CODE_USER, req)
    .pipe(
      map( (res) =>
        {
          return res;
        }
      )
    );

  }

  verificationCode(userData: VerificationCodeRequest): Observable<VerificationCodeAnswer> {
    const { type, ...req }: any  = userData;

    return this.httpClient.post<VerificationCodeAnswer>(VERIFICATION_CODE_USER, req)
    .pipe(
      map( (res) =>
        {
          return res;
        }
      )
    );

  }

}
