<div
  id="file-item-info"
  class="file-item"
  [ngClass]="{
    'border-new': history.status === 'new',
    'border-active': history.status === 'active',
    'border-failed': history.status === 'failed' || history.status === 'error',
    'border-done': history.status === 'done'
  }"
>

  <div class="file-info">
    <div>
      <span class="file-status" [ngClass]="{
        'new': history.status === 'new',
        'active': history.status === 'active',
        'failed': history.status === 'failed' || history.status === 'error',
        'done': history.status === 'done'
        }">{{history.status}}</span>
    </div>
    <div>
      <span class="file-name"><b>{{history.file_request}}</b></span>
    </div>
    <div>
      <span class="file-words">{{history.word_count}} words</span>
    </div>
  </div>

  <div class="file-action">
    <div>

      <button
        mat-raised-button
        class="btn-new-text"
        *ngIf="history.status === 'new'"
        (click)="onRewrite(history.history_id)"
      >
        Get New Text
      </button>

      <button
        mat-raised-button
        class="btn-new-text"
        *ngIf="history.status === 'new'"
        (click)="onHumanized(history.history_id)"
      >
        Humanized Text
      </button>

      <!-- To retry -->

      <button
        mat-raised-button
        class="btn-to-retry"
        *ngIf="history.status === 'failed' || history.status === 'error'"
        (click)="onRewrite(history.history_id)"
      >
        Get New Text
      </button>

      <button
        mat-raised-button
        class="btn-to-retry"
        *ngIf="history.status === 'failed' || history.status === 'error'"
        (click)="onHumanized(history.history_id)"
      >
        Humanized Text
      </button>

      <div *ngIf="history.status === 'active'">
        <i class="fas fa-cog fa-spin"></i>
      </div>

      <button
        mat-raised-button
        class="done"
        *ngIf="history.status === 'done'"
        (click)="onDownloadFile(history.history_id, history.file_request)"
      >
        Download
      </button>

    </div>
  </div>

  <div class="file-add-but">
    <i
      *ngIf="history.status === 'new' || history.status === 'failed' || history.status === 'error'"
      class="fas fa-trash"
      (click)="onTrash(history.history_id)"
    ></i>
  </div>

  <div class="flag-language">
    {{history.name_language}}
  </div>

</div>
