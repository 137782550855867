

<div class="auth-container">

  <mat-card>
    <mat-card-header>
      <div mat-card-avatar class="header-image">
        <a href="https://newtxt.io/"><img src="assets/newtxt_white.webp" alt="NewTXT"></a>
      </div>
    </mat-card-header>
  </mat-card>

    <mat-card>

      <mat-card-header>
        <div class="header-text">
          <h2 mat-card-title>Account Recovery</h2>
        </div>
      </mat-card-header>

      <form *ngIf="onFormLogin" [formGroup]="formVerificationUser" (ngSubmit)="onGetCode()">
        <mat-card-content>

          <ng-container>

            <mat-form-field>
              <mat-label>Login or Email</mat-label>
              <input matInput formControlName="user">
              <mat-error *ngIf="formVerificationUser.get('user')?.errors?.['required']">
                Required.
              </mat-error>
              <mat-error *ngIf="formVerificationUser.get('user')?.errors?.['userExists']">
                Account does not exist.
              </mat-error>
            </mat-form-field>

          </ng-container>

          <div class="block-button">

            <button
              type="submit"
              mat-raised-button color="primary"
              [disabled]="!formVerificationUser.valid"
            >
              Recover account
            </button>

            <button
              type="button"
              mat-raised-button color="primary"
              (click)="onShowLogIn()"
            >
              Back to log in
            </button>

          </div>

        </mat-card-content>
      </form>

      <form [formGroup]="formCode" *ngIf="formVerificationUser.valid && onFormCode" (ngSubmit)="onConfirmCode()">
        <mat-card-content>

          <p>A recovery code has been sent to your email <b>{{ emailSendCode | hideEmail }}</b>.</p>

          <div class="code-inputs">

            <ng-container *ngFor="let control of formCode.controls | keyvalue; let i = index" class="code-input-container">
              <div  style="margin: 3px;">
                <input
                  class="code-input"
                  #codeInput
                  [formControlName]="control.key"
                  maxlength="1"
                  (input)="onInput($event, i)"
                >
              </div>
              <span *ngIf="(i + 1) % 3 === 0 && i < 8" class="dash" style="color: white; margin: 5px;"></span>
            </ng-container>

          </div>

          <div class="block-button">

            <button
              type="submit"
              mat-raised-button
              color="primary"
              [disabled]="!formCode.valid"
            >
              Verification
            </button>

            <button
              mat-raised-button
              color="accent"
              (click)="onResendCode()"
              [disabled]="!canResend"
            >
              Resend Code ({{ resendTimer }})
            </button>

          </div>

        </mat-card-content>
      </form>



      <form [formGroup]="formRecover" *ngIf="onFormRecover" (ngSubmit)="onRecover()">
        <mat-card-content>

          <mat-form-field>
            <mat-label>Password</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
            <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="formRecover.get('password')?.errors?.['required']">
              Password is required.
            </mat-error>
            <mat-error *ngIf="!formRecover.get('password')?.errors?.['required'] && formRecover.get('password')?.errors?.['minlength']">
              Password must be at least 6 characters.
            </mat-error>
            <mat-error *ngIf="!formRecover.get('password')?.errors?.['required'] && !formRecover.get('password')?.errors?.['minlength'] && formRecover.get('password')?.errors?.['pattern']">
              Password must have at least one uppercase letter and one number.
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Confirm Password</mat-label>
            <input matInput [type]="hideConfirm ? 'password' : 'text'" formControlName="confirmPassword">
            <mat-icon matSuffix (click)="hideConfirm = !hideConfirm">{{hideConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="formRecover.get('confirmPassword')?.errors?.['required']">
              Confirming password is required.
            </mat-error>
            <mat-error *ngIf="formRecover.errors?.['passwordMismatch'] && formRecover.get('password')?.touched && formRecover.get('confirmPassword')?.touched">
              Passwords do not match!
            </mat-error>
          </mat-form-field>

          <div class="block-button">
            <button type="submit" mat-raised-button color="primary" [disabled]="!formRecover.valid">
              Change password
            </button>
          </div>

        </mat-card-content>
      </form>

    </mat-card>

    <app-info-policy></app-info-policy>

  <div id="login-footer">
    <app-footer-company></app-footer-company>
  </div>

</div>

