import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PaddleService } from "../services/paddle.service";
import { PaddleCheckout, PaddleCheckoutFailed, PaddleCheckoutSuccess } from "./paddle.actions";
import { catchError, map, of, switchMap, tap } from "rxjs";
import { GetPaddleDataAnswer, GetPaddleDataRequest } from "../interface/paddle.interface";

@Injectable()
export class paddleEffects {

  PaddleCheckout$ = createEffect(() => this.actions$.pipe(
    ofType(PaddleCheckout),
    switchMap((data: GetPaddleDataRequest) => this.paddleService.createCheckout(data).pipe(
      map((plan: GetPaddleDataAnswer) => {
        plan.period = data.period;
        return PaddleCheckoutSuccess(plan);
      }),
      catchError(error => of(PaddleCheckoutFailed()))
    ))
  ));

  PaddleCheckoutOpen$ = createEffect(() => this.actions$.pipe(
    ofType(PaddleCheckoutSuccess),
    tap((action: GetPaddleDataAnswer) => {
      this.paddleService.checkoutOpen(action);
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store$: Store,
    private paddleService: PaddleService
  ){}

}
