import { Injectable } from '@angular/core';
import { AppCookieService } from '../cookie/app-cookie.service';
import { STATUS_CLIENT, VERIFIED_USER } from '../../global/variables/app';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {

  constructor(
    private appCookieService: AppCookieService
  ) { }

  saveInfoByUser(): void {

    this.appCookieService.setCookieForever(VERIFIED_USER, '1');
    localStorage.setItem(VERIFIED_USER, '1');

  }

  saveStatusClient(status: string | number | boolean): void {
    this.appCookieService.setCookieForever(STATUS_CLIENT, String(status));
    localStorage.setItem(STATUS_CLIENT, String(status));
  }

  getStatusClient(): boolean {

    let _statusGetCookie = this.appCookieService.getCookie(STATUS_CLIENT);
    let _statusGetItem = localStorage.getItem(STATUS_CLIENT);
    if(_statusGetCookie == "1")
      return true;
    else if(_statusGetItem == "1")
      return true;
    else
      return false;

  }

}
