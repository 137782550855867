import { createFeatureSelector, createSelector } from "@ngrx/store";
import { UserState } from "../interface/user.interface";
import { USER_FEATURENAME } from "./user.reducer";

const getFeature = createFeatureSelector<UserState>(USER_FEATURENAME);

export const userSettingData = createSelector(
  getFeature,
  state => state.setting
)

export const userData = createSelector(
  getFeature,
  state => state.user
)

export const userBillingData = createSelector(
  getFeature,
  state => state.billing
)
