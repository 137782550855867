import { Component } from '@angular/core';

@Component({
  selector: 'app-loading-payment-dialog',
  templateUrl: './loading-payment-dialog.component.html',
  styleUrl: './loading-payment-dialog.component.sass'
})
export class LoadingPaymentDialogComponent {

}
