import { Injectable } from '@angular/core';
import { Observable, Subscription, catchError, filter, finalize, map, mergeMap, of, switchMap, takeWhile, tap, throwError, timer } from 'rxjs';
import {GET_BILLING, GET_SETTING_USER, GET_USER_DATA, PLAN_CHECK, UPLOUD_ICO} from '../../../global/request/user';
import { HttpClient } from '@angular/common/http';
import { Billing, BillingAnswer, GetSettingUserAnswer, GetUserDataUserAnswer, GetUserDataUserRequest, PlanCheckRequest } from '../interface/user.interface';
import { LoadingPaymentDialogComponent } from '../../../../components/dialog/loading-payment-dialog/loading-payment-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { PlanCheck, PlanCheckSuccess } from '../store/user.actions';
import { ErrorLoadingPaymentDialogComponent } from '../../../../components/dialog/error-loading-payment-dialog/error-loading-payment-dialog.component';
import { GetSubscriptionAnswer, SubscriptionUser } from '../../subscription-store/interface/subscription.interface';
import {UploadFileAnswer} from "../../app-store/interface/uloadfile.interface";
import {UPLOAD_FILE} from "../../../global/request/app";
import {UploadIcoAnswer} from "../interface/uloadico.interface";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private firstPhaseAttempts = 0;
  private secondPhaseAttempts = 0;
  private checkSubscription: Subscription = new Subscription();
  private successSubscription: Subscription = new Subscription();

  dialogRef!: MatDialogRef<LoadingPaymentDialogComponent>;

  constructor(
    private httpClient: HttpClient,
    private store$: Store,
    private actions$: Actions,
    private dialog: MatDialog
  )
  {
    this.actions$.pipe(
      ofType(PlanCheckSuccess),
      tap(() => {
        this.closeLoadingPaymentDialog()
        this.reset();
      })
    ).subscribe();
  }

  uploadIco(formData: FormData): Observable<UploadIcoAnswer> {
    return this.httpClient.post<UploadIcoAnswer>(UPLOAD_FILE, formData)
      .pipe(
        mergeMap(res => {
          if ('error' in res || 'warning' in res) {
            return throwError(res);
          }
          return [res];
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }


  getUserData(): Observable<GetUserDataUserAnswer> {

    return this.httpClient.post<GetUserDataUserAnswer>(GET_USER_DATA, {})
    .pipe(
      map( (res) => res)
    );

  }

  getSettingUser(): Observable<GetSettingUserAnswer> {

    return this.httpClient.post<GetSettingUserAnswer>(GET_SETTING_USER, {})
    .pipe(
      map( (res) => res)
    );

  }

  openLoadingPaymentDialog(): void {
    this.dialogRef = this.dialog.open(LoadingPaymentDialogComponent, {
      width: '600px',
      disableClose: true,
      data: {}
    });
  }

  closeLoadingPaymentDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  openErrorLoadingPaymentDialog(): void {
    this.dialog.open(ErrorLoadingPaymentDialogComponent, {
      width: '600px',
      data: {}
    });
  }

  startPlanCheck(data: PlanCheckRequest): void {

    const firstPhaseMaxAttempts = 12;
    const secondPhaseMaxAttempts = 5;
    const firstPhaseInterval = 10000;
    const secondPhaseInterval = 60000;

    this.openLoadingPaymentDialog();

    this.reset();

    this.checkSubscription = timer(0, firstPhaseInterval).pipe(
      takeWhile(() => this.firstPhaseAttempts < firstPhaseMaxAttempts),
      tap(() => {
        this.firstPhaseAttempts++;
        this.store$.dispatch(PlanCheck(data));
      }),
      switchMap(() => {
        if (this.firstPhaseAttempts >= firstPhaseMaxAttempts) {
          return timer(firstPhaseInterval, secondPhaseInterval).pipe(
            takeWhile(() => this.secondPhaseAttempts < secondPhaseMaxAttempts),
            tap(() => {
              this.secondPhaseAttempts++;
              this.store$.dispatch(PlanCheck(data));
            })
          );
        }
        return of(null);
      }),
      filter(value => value !== null),
      finalize(() => {
        if (this.firstPhaseAttempts === firstPhaseMaxAttempts && this.secondPhaseAttempts === secondPhaseMaxAttempts)
        {
          this.closeLoadingPaymentDialog()
          this.openErrorLoadingPaymentDialog()
        }
      })
    ).subscribe();

  }

  reset(): void {
    this.checkSubscription.unsubscribe();
    this.successSubscription.unsubscribe();
    this.firstPhaseAttempts = 0;
    this.secondPhaseAttempts = 0;
  }

  planCheck(data: PlanCheckRequest): Observable<SubscriptionUser> {

    const {type, ...req}: any = data;

    return this.httpClient.post<GetSubscriptionAnswer>(PLAN_CHECK, req)
    .pipe(
      mergeMap(res => {
        if ('error' in res || 'warning' in res) {
          return throwError(res);
        }
        return [res.data];
      }),
      catchError(error => {
        return throwError(error);
      })
    );

  }

  getBilling(): Observable<Billing[]> {
    return this.httpClient.post<BillingAnswer>(GET_BILLING, {})
    .pipe(
      mergeMap(res => {
        if ('error' in res || 'warning' in res) {
          return throwError(res);
        }
        return of(res.data);
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

}
