import { Injectable } from '@angular/core';
import { AuthState, AuthRequest, AuthAnswer, GoogleData } from '../interface/auth.interface';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AUTHORIZATION_USER, GOOGLE_AUTHORIZATION_USER } from '../../../global/request/authorization';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ErrorAuthDialogComponent } from '../../../../components/dialog/error-auth-dialog/error-auth-dialog.component';
import { ErrorAuthGoogleDialogComponent } from '../../../../components/dialog/error-auth-google-dialog/error-auth-google-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private httpClient: HttpClient,
    private dialog: MatDialog
  ) { }

  authorizationUser(auth: AuthRequest): Observable<AuthState> {

    return this.httpClient.post<AuthAnswer>(AUTHORIZATION_USER, {
      email: auth.email,
      password: auth.password
    })
    .pipe(
      mergeMap((res: AuthAnswer) => {
        if ('error' in res || 'warning' in res) {

          if( res.code === 21006 )
          {

            this.dialog.open(ErrorAuthDialogComponent, {
              data: {
                message: 'Invalid email address or password. Please check your input and try again.',
                action: 'forgot_account'
              }
            });

          }
          else if( res.code === 21005 )
          {

            this.dialog.open(ErrorAuthDialogComponent, {
              data: {
                message: 'Invalid email address or password. Please check your input and try again.',
                action: 'sing_up'
              }
            });

          }

          return throwError(res);

        }

        return of({
          token: res.data.token,
          status: true,
        });

      }),
      catchError(error => {
        return throwError(error);
      })
    );

  }

  decodeBase64Url(base64UrlString: string): string {
    return decodeURIComponent(atob(base64UrlString.replace(/\-/g, '+').replace(/\_/g, '/')).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  getJwtPayload(token: string): any {
    console.log(token);

    const base64Url = token.split('.')[1];
    const base64 = this.decodeBase64Url(base64Url);
    return JSON.parse(base64);
  }

  authGoogle(data: GoogleData): Observable<AuthState> {

    const {type, ...req}: any = data;

    return this.httpClient.post<AuthAnswer>(GOOGLE_AUTHORIZATION_USER, {
      aud: req.aud,
      sub: req.sub,
      email: req.email,
      email_verified: req.email_verified,
      family_name: req.family_name,
      given_name: req.given_name,
      name: req.name,
      picture: req.picture
    })
    .pipe(
      mergeMap((res: AuthAnswer) => {
        if ('error' in res || 'warning' in res) {

          if( res.code === 21005 )
          {

            this.dialog.open(ErrorAuthGoogleDialogComponent, {
              data: {
                message: 'Your Google account is not registered.',
                action: 'sing_up'
              }
            });

          }

          return throwError(res);

        }

        return of({
          token: res.data.token,
          status: true,
        });

      }),
      catchError(error => {
        return throwError(error);
      })
    );

  }

}
