import { createReducer, on } from '@ngrx/store';
import { GetHistoryFileSuccess, GetLanguagesSuccess, ReceivingFileFailed, ReceivingFileSuccess, ResetApp, ResetPromoFile } from './app.actions';
import { AppState } from '../interface/app.interface';

export const APP_FEATURENAME = "app";

const appState: Partial<AppState> = {
  languages: [],
  file: {
    promoFile: {
      status: false,
      languageId: null,
      originalName: "",
      newName: "",
      fileType: ""
    }
  },
  list: {
    files: {
      history: [],
      count: 0,
      limit: 10,
      page: 1,
      totalPages: 1
    }
  }
};

export const appReducer = createReducer(

  appState,
  on(GetLanguagesSuccess, (state, {data}) => ({
    ...state,
    languages: data
  })),

  on(ReceivingFileSuccess, (state, promoFile) => ({
    ...state,
    file: {
      ...state.file,
      promoFile: {
        ...promoFile,
        status: true
      }
    }
  })),

  on(ResetPromoFile, (state) => ({
    ...state,
    file: {
      ...state.file,
      promoFile: {
        status: false,
        languageId: null,
        originalName: "",
        newName: "",
        fileType: ""
      }
    }
  })),

  on(ReceivingFileFailed, (state) => ({
    ...state,
    file: {
      ...state.file,
      promoFile: {
        status: false,
        languageId: null,
        originalName: "",
        newName: "",
        fileType: ""
      }
    }
  })),

  on(GetHistoryFileSuccess, (state, data) => ({
    ...state,
    list: {
      ...state.list,
      files: data
    }
  })),

  on(ResetApp, (state) => ({
    ...state,
    ...appState
  }))

);
